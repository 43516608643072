//betapage.js

import React from 'react'    

const BetaPage = () => {

  return (
    <div className='flex flex-col px-6 pt-24 lg:px-8 flex-1 dark-primary-bg'>
      <div className='w-1/2'>
        <h1 className='text-6xl text-white font-bold mb-4'>TRADEBOOKS BETA</h1>
        <h2 className='text-white mb-4'>We are excited to announce that Tradebooks is currently in its beta phase. 
          As we refine our platform, we are offering a limited number of users the chance 
          to gain early access and help us shape the future of our service. If you are 
          interested in participating, please enter your email below. We will contact you
          if you are selected for beta access.
        </h2>
        <h2 className='text-white mb-4'>At Tradebooks, we are dedicated to creating an affordable and straightforward 
          trading journal that suits a variety of transactions, whether it's trading or gambling. 
          Our goal is to help you keep track of your financials with ease and clarity. We 
          strive to make the process as simple and hassle-free as possible, eliminating 
          unnecessary noise and complexity.
        </h2>
        <h2 className='text-white'>Check back soon for Beta Access</h2>
        <h2 className='text-white'>Thank you for your interest and support!</h2>
      </div>
      <div className='text-white'>
        <h2>Join Our Beta Program</h2>
        <h2>Enter your email for a chance to get early access.</h2>
        {/* <form>
          <div className='flex'>
            <input
              type='email'
              placeholder='Enter your email'
              className='flex-grow p-2 rounded-l-md border-2 border-gray-300'
            />
            <button
              type='submit'
              className='flex-shrink-0 p-2 bg-blue-500 text-white rounded-r-md border-2 border-blue-500'
            >
              Submit
            </button>
          </div>

        </form> */}
      </div>
    </div>
  );
};

export default BetaPage;