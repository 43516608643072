import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();


  useEffect(() => {
    // Check for the persistbeacon token in localStorage
    const persistBeacon = localStorage.getItem('persistbeacon');

    // If the persistbeacon token is present, set isAuthenticated to true
    if (persistBeacon) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

    const login = () => {
      setIsAuthenticated(true);
      navigate('/dashboard');
    };

    const logout = () => {
      setIsAuthenticated(false);
      document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.removeItem('persistbeacon');
      navigate('/loggedout');
      console.log("Logout successful:refresh invaldiated");
    };
  
    return (
      <AuthContext.Provider value={{ isAuthenticated, logout, login }}>  
        {children}
      </AuthContext.Provider>
    );
  };