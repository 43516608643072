// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth/AuthContext'; // Adjust the import path as necessary

const LoggedOutModal = () => {

    const navigate = useNavigate();
  
    const handleLoginRedirect = () => {
      navigate('/login');
    };
  
    return (
      <div className="fixed text-white inset-0 bg-gray-900 bg-opacity-90 flex items-center justify-center">
        <div className="dark-secondary-bg p-16 rounded-lg shadow-md text-center border border-customGray">
          <h2 className="text-2xl mb-4">You've been logged out</h2>
          <p className="mb-4">Your session has expired. Please log in again.</p>
          <button 
            onClick={handleLoginRedirect} 
            className="bg-twpurple hover:bg-indigo-600 text-white px-4 py-2 rounded transition duration-300"
          >
            Login
          </button>
        </div>
      </div>
    );
  };

export default LoggedOutModal;
