import React, { useState } from 'react';
// import { ChevronLeft, ChevronRight } from "lucide-react"

const SettingsPage = () => {

	return (
		<div></div>
	);
};

export default SettingsPage;




// so what do i want on my settings page

// the option to upgrade to pro if not on a pro account

// the option to unsubscribe from pro if you are on a pro account

// the option to change your timezone, which would then change time of all trades currently
// active and completed and new trades would just be on the new timeline, big job???
// decide on how many time zones to offer, does it matter if the logic for changing time zone is solid

// delete account - option to delete account and all data permanently, create intermediary steps for safety
// also notify users of a period of e.g 30 days to reverse this action before all data is permenently deleted



