// PublicFrontPage.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './Header';
import Login from './auth/login';
import Register from './auth/register';
import Home from './Home';
import LoggedOutModal from './LoggedOutModal';
import BetaPage from './auth/BetaPage';

const PublicFrontPage = () => {
  return (
    <div className='dark-primary-bg w-screen min-h-screen overflow-hidden'>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/register" element={<BetaPage />} />
        <Route path="/" element={<Home />} />
        <Route path='/loggedout' element={<LoggedOutModal/>} />
        {/* More routes can be added here if needed */}
      </Routes>
    </div>
  );
};

export default PublicFrontPage;

