// StrategiesPage component
import React, { useState, useEffect, useContext } from 'react';
import StrategyGroupCard from './content/StrategyGroupCard';
import StrategyCard from './content/StrategyCard';
import CreateGroupButton from './CreateGroupButton';
import CreateStrategyCard from './CreateStrategyCard';
import CreateStrategyForm from './content/CreateStrategyForm';
import CreateGroupForm from './content/CreateGroupForm';

import { GroupAndStrategyContext } from './GroupAndStrategyContext';
import StrategyTable from './content/StrategyTable';

const StrategiesPage = () => {
  const { strategyGroups, allStrategies } = useContext(GroupAndStrategyContext);
  const [showForm, setShowForm] = useState(false);

  // Derived state for ungrouped strategies
  const ungroupedStrategies = allStrategies.filter(strategy => !strategy.group);

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  // Sort strategy groups so that "Ungrouped" is always last
  const sortedStrategyGroups = [...strategyGroups].sort((a, b) => {
    if (a.name === 'Ungrouped') return 1;
    if (b.name === 'Ungrouped') return -1;
    return a.name.localeCompare(b.name);
  });

  return (
    <div className=" text-white min-h-screen">
      {/* <h1 className="text-4xl font-bold mb-6">Strategies</h1> */}
      <div className="mb-8">
        <h1 className="text-greyishtext text-3xl font-semibold mb-4">Strategy Groups</h1>
        {showForm && <CreateGroupForm onCancel={toggleFormVisibility}/>}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {!showForm && <CreateGroupButton onClick={toggleFormVisibility} />}
          
          {sortedStrategyGroups.map(group => (
            <StrategyGroupCard key={group.id} id={group.id} slug={group.slug} groupName={group.name} 
            description={group.description} />
          ))}
        </div>
      </div>
      {/* <div>
        <h2 className="text-2xl font-semibold mb-4">Ungrouped Strategies</h2>
        {showForm && <CreateStrategyForm onCancel={toggleFormVisibility}/>}
        <div className="flex flex-wrap gap-4 mt-4">
          {!showForm && <CreateStrategyCard inlineForm onInlineFormClick={toggleFormVisibility} />} 
          {ungroupedStrategies.map(strategy => (
            <StrategyCard key={strategy.id} id={strategy.id} slug={strategy.slug} strategyName={strategy.name} 
            description={strategy.description} />
          ))}
          
        </div>
      </div> */}
      {/* <StrategyTable strategies={ungroupedStrategies} title={"Ungrouped Strategies"} /> */}
    </div>
  );
};

export default StrategiesPage;
















// const [strategyGroups, setStrategyGroups] = useState([]);
  // const [ungroupedStrategies, setUngroupedStrategies] = useState([]);

  // useEffect(() => {
  //   const loadStrategyGroups = async () => {
  //     try {
  //       const response = await fetchWithToken('api/strategygroups/');
  //       if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       setStrategyGroups(data);
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };

  //   loadStrategyGroups();
  // }, []);

  // useEffect(() => {
  //   const fetchUngroupedStrategies = async () => {
  //       try {
  //           // Example endpoint call to get ungrouped strategies
  //           const response = await fetch('/api/strategies?grouped=false');
  //           if (!response.ok) {
  //               throw new Error('Network response was not ok');
  //           }
  //           const data = await response.json();
  //           // Assuming you have a state setter for strategies
  //           setUngroupedStrategies(data);
  //       } catch (error) {
  //           console.error('Error fetching ungrouped strategies:', error);
  //       }
  //   };

  //   fetchUngroupedStrategies();
  // }, []);