import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EnterTradeButton from './EnterTradeButton';
import CreateStrategyButton from '../CreateStrategyButton';
import CreateStrategyForm from './CreateStrategyForm';
import EnterTrade from './EnterTrade';


const StrategyTable = ({ strategies, id, slug, strategyName, description, title, groupId, refreshStrategies }) => {
    const navigate = useNavigate();
    const [hasStrategies, setHasStrategies] = useState(false);
    // const handleUngroupedStrategyClick = () => {
    //     navigate(`/strategies/${id}-${slug}`);
    //   }
    const [showForm, setShowForm] = useState(false);
    const [activeStrategyId, setActiveStrategyId] = useState(null);
    const [showEnterTrade, setShowEnterTrade] = useState(false);

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
        // Close Enter Trade form when opening Create Strategy form
        if (!showForm) {
            setActiveStrategyId(null);
        }
      };
    
    

    const toggleTradeEntryVisibility = (strategyId) => {
        setActiveStrategyId(activeStrategyId === strategyId ? null : strategyId);
        setShowForm(false);
    }; 

    const hideEnterTradeForm = () => {
        setActiveStrategyId(null);
    };
    

    useEffect(() => {
        if (strategies.length > 0) {
            setHasStrategies(true)
        }
    }, [strategies]);

    const strategyCreatedCallback = () => {
        setShowForm(false)
        refreshStrategies();
    };

  return (
    <div className="dark-secondary-bg border border-customGray grid grid-cols-1 rounded-lg">
        <div className='h-16 flex items-center justify-between px-8 border-b  border-customGray'>
            <div className='text-white text-xl font-semibold'>{title}</div>
            {/* <div>fuhf</div> */}
            <CreateStrategyButton inlineForm onInlineFormClick={toggleFormVisibility}/>
        </div>
        <div>
            {showForm && <CreateStrategyForm groupId={groupId} onCancel={toggleFormVisibility} onStrategyCreated={strategyCreatedCallback}/>}
        </div>
        {/* {strategies.map((strategy, index) => (
                <div key={strategy.id} className={`card overflow-hidden ${index !== strategies.length - 1 ? 'border-b border-customGray' : ''}`}>
                    <div className="p-2 grid grid-cols-3  items-center px-8">
                        <div className="col-span-1">
                            <p className="text-white text-base">{strategy.name}</p>
                        </div>
                        <div className="col-span-1 flex justify-start">
                        <p className="text-gray-600">{strategy.description.length > 30 ? `${strategy.description.slice(0, 30)}...` : strategy.description}</p>
                        </div>
                        
                        <div className="col-span-1 flex justify-end">
                            <EnterTradeButton groupId={strategy.id} variant="strategyTable" className="ml-auto" />
                        </div>
                    </div>
                </div>
            ))} */}



            {hasStrategies ? (
                <>
                    {strategies.map((strategy, index) => (
                        <React.Fragment key={strategy.id}>
                            <div className={`card hover:bg-indigo-950 overflow-hidden ${index !== strategies.length - 1 && activeStrategyId !== strategy.id ? 'border-b border-customGray' : ''} ${activeStrategyId === strategy.id ? 'bg-darkprimbg' : 'bg-transparent'}`}>
                                <div className="p-2 grid grid-cols-3 items-center px-8">
                                    <div className="col-span-1">
                                        <p className="text-white text-base">{strategy.name}</p>
                                    </div>
                                    <div className="col-span-1 flex justify-start">
                                        <p className="text-gray-600">{strategy.description.length > 30 ? `${strategy.description.slice(0, 30)}...` : strategy.description}</p>
                                    </div>
                                    <div className="col-span-1 flex justify-end">
                                        <EnterTradeButton strategyId={strategy.id} groupId={groupId} variant="strategyTable" className="text-white font-bold px-2 rounded ml-auto bg-purpletest hover:bg-indigo-900 font-semibold py-1.5 w-auto text-sm " inlineForm onInlineFormClick={() => toggleTradeEntryVisibility(strategy.id)} />
                                    </div>
                                </div>
                            </div>
                            {activeStrategyId === strategy.id && <EnterTrade strategyId={strategy.id} groupId={groupId} onCancel={hideEnterTradeForm}/>}
                        </React.Fragment>
                    ))}
                </> 
            ) : (
                <div className='text-gray-600 text-md my-2 text-center'>No Strategies</div>
            )}
    </div>
  );
};

export default StrategyTable;
