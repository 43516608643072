// CreateStrategyCard.js

import React from 'react';
// import { useSidebarActive } from './SidebarActiveContext';
import { useNavigate } from 'react-router-dom';


const CreateStrategyButton = ({ inlineForm, onInlineFormClick }) => {
  const navigate = useNavigate();
  // const { setSidebarActiveContent } = useSidebarActive();

  const handleClick = () => {
    if (inlineForm && onInlineFormClick) {
        onInlineFormClick();
    } else {
        navigate('/create-strategy');
    }
};


    return (
      <div
        className="flex items-center p-2 justify-center hover:border hover:border hover:border-customGray rounded-lg dark-secondary-bg hover:bg-darkprimbg cursor-pointer"
        onClick={handleClick}
      >
        <div className="text-center text-gray-500 hover:text-gray-300">
          <svg
            className="w-4 h-4 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <p className=" text-md">New Strategy</p>
        </div>
      </div>
    );
  };
  
  export default CreateStrategyButton;
  