import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Plus} from "lucide-react"
import { useFetchWithToken } from '../../../Api';


const UpgradeButton = ({ variant, destination = "", className, children}) => {
    // console.log("Received groupId in EnterTradebutton:", groupId);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const fetchWithToken = useFetchWithToken();

    const handleButtonClick = async () => {
        setLoading(true);
        try {
            const response = await fetchWithToken('api/create-checkout-session/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any other headers if needed, such as authorization headers
                },
                body: JSON.stringify({
                    // Include any necessary data in the request body
                    "price_id": "price_1PCKDiHv0qT9PRh6fxKALzad"
                }),
            });
            const data = await response.json();
            // Redirect to Stripe Checkout using the obtained session_id
            // const stripeCheckoutUrl = `https://checkout.stripe.com/pay/cs_test_4xxxxxxxxxxxxxxxxxxxxxx?sessionId=${data.session_id}`;
            // window.location.href = stripeCheckoutUrl;
            // Log the checkout URL to the console
            console.log('Checkout URL:', data.url);
            window.location.href = data.url;

        } catch (error) {
            // Handle errors if the upgrade request fails
            console.error('Error upgrading subscription:', error);
        } finally {
            setLoading(false);
        }
    };

    // const buttonStyle = `bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
    //     variant === 'fullWidth' ? 'w-full' : 'w-auto'
    // }`;

    // Define button styles based on the variant
    let buttonClasses = 'text-white font-bold px-4 rounded';
    if (variant === 'groupPage') {
        buttonClasses += ' bg-green-500 hover:bg-green-700 py-2'; // Full width, green color, w-full taken out
    } else if (variant === 'strategyItem') {
        buttonClasses += ' bg-red-500 hover:bg-red-700 py-1 w-auto'; // Auto width, red color
    } else if (variant === 'strategyTable') {
        buttonClasses += ' bg-purpletest hover:bg-indigo-900 font-semibold py-1.5 w-auto text-sm'; // Auto width, red color
    } else {
        buttonClasses += ' bg-blue-500 hover:bg-blue-700 py-2 w-auto'; // Default styling
    }

    return (
        <button onClick={handleButtonClick} className={className}
         disabled={loading}
        >
            {children || 'Upgrade To Pro'}
        </button>
    );
};

export default UpgradeButton;


//className='bg-green-600 hover:bg-green-500 flex font-medium py-2'