// Dashboard.js
import React from 'react';
import Sidebar from './sidebar'; 
import Content from './content';
import { SidebarProvider } from './SidebarContext';
import { GroupAndStrategyProvider } from './GroupAndStrategyContext';
import { PlanTypeProvider } from './PlanTypeContext';

const Dashboard = () => {

  return (
    <PlanTypeProvider>
      <SidebarProvider>

        {/* <div className="flex dark-primary-bg"> */}
        <div className="flex dark-primary-bg">

        <GroupAndStrategyProvider>
          <Sidebar />
          
          
            <Content />
          </GroupAndStrategyProvider>
          
        </div>

      </SidebarProvider>
    </PlanTypeProvider>
  );
}

export default Dashboard;
