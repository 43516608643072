// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; 

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
      };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Client Side Validation
        // Make sure email and password are included
        if (!formData.email || !formData.password) {
            setError('Please enetr you email and password.');
            return;
        }
        // Make sure email is in proepr format, test againt regex format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)){
            setError('Please enter a valid email address.');
            return;
        }
        // Make sure email doesnt conatin any specific characters
        const forbiddenCharsEmail = /[<>]/;
        if (forbiddenCharsEmail.test(formData.email)){
            setError('Email address contains invalid characters');
            return;
        }
        // Make sure password is prper format, no dissallowed characters
        if (forbiddenCharsEmail.test(formData.password)){
            setError('Password should not contain certain characters: <, >.');
            return;
        }
        // Clear any existing errors
        setError('');

        try {
            const response = await fetch(`${LOGIN_URL}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...formData, rememberMe }), // Include rememberMe
                credentials: 'include', // Include credentials in the request
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Login successful:", data);
                // Store the persistbeacon token in local storage
                localStorage.setItem('persistbeacon', data.persistbeacon);
                localStorage.setItem('planType', data.plan_type);
                // Assume login is successful and update authentication state
                login(); 
            } else {
                const errorData = await response.json();
                setError(errorData.detail || 'Login failed. Please try again.');
                console.error("Login failed:", errorData);
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred during login. Please try again.');
        }
    };

    return (
        <div className="flex flex-col px-6 pt-24 lg:px-8 flex-1">
            <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight  text-white mb-4">Log In</h2>
                <p className='text-gray-400 text-sm mb-2'>Not a member? <span className='text-twpurple'><a href='/register'>Sign Up</a></span></p>
                {error && <p className="text-red-500">{error}</p>} {/* Display error message */}
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="mb-4">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            className="focus:outline-none block w-full dark-secondary-bg rounded-md border-0 px-2.5 py-2.5 text-white shadow-sm ring-1 ring-customGray placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                            required
                            autoComplete='email'
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Enter your password"
                            className="focus:outline-none block w-full dark-secondary-bg rounded-md border-0 px-2.5 py-2.5 text-white shadow-sm ring-1 ring-inset ring-customGray placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                            required
                            autoComplete='current-password'
                        />
                    </div>
                    <div className='flex justify-between'>
                        <div>
                            <input type="checkbox" id="rememberMe" name="rememberMe" checked={rememberMe} onChange={handleRememberMeChange} />
                            <label className="text-gray-400 ml-2" htmlFor="rememberMe">Remember Me</label>
                        </div>
                        <div className='text-twpurple'><a>Forgot Password?</a></div>
                    </div>
                    <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2.5 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Log In
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
