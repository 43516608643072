// CreateGroupForm.js
import React, { useState, useContext } from 'react';
import { useFetchWithToken } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';

const CreateGroupForm = ({onCancel}) => {
  // const [groupName, setGroupName] = useState('');
  // const [description, setDescription] = useState('');  // Added a state for the description
  const navigate = useNavigate();
  const { fetchData } = useContext(GroupAndStrategyContext);
  const fetchWithToken = useFetchWithToken();
  const {error, setError} = useState('');
  const [formData, setFormData] = useState({ name: '' , description: '' })
  console.log(formData)

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetchWithToken('api/strategygroups/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData }),  // Assuming your API expects 'name' and 'description' keys
      });

      if (response.ok) {
        const newGroup = await response.json();
        console.log('Group created:', newGroup);
        setFormData({ name: '', description: '' });
        fetchData();  // Refresh the data in the context
        navigate(`/strategy-groups/${newGroup.id}-${newGroup.slug}`);
      } else {
        throw new Error('Failed to create group');
      }
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  return (
    <form className="border border-customGray dark-secondary-bg p-4 rounded-lg mb-8 " onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="group-name" className="block text-white mb-2">Group Name:</label>
        <input
          type="text"
          id="group-name"
          value={formData.groupName}
          onChange={(e) => setFormData({...formData, name: e.target.value})}
          className="bg-gray-700 text-white p-2 rounded w-full"
          placeholder="Enter group name"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="description" className="block text-white mb-2">Description:</label>
        <textarea
          id="description"
          value={formData.description}
          onChange={(e) => setFormData({...formData, description: e.target.value})}
          className="bg-gray-700 text-white p-2 rounded w-full"
          placeholder="Enter group description"
        />
      </div>
      <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Create Group
      </button>
      <button onClick={onCancel} className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Cancel
      </button>
    </form>
  );
};

export default CreateGroupForm;
