import React, { createContext, useState, useEffect } from 'react';
import fetchWithToken from '../../Api'; // Adjust the import path to where your Api fetch method is located
import { useNavigate } from 'react-router-dom';
import { useFetchWithToken } from '../../Api'; 

export const GroupAndStrategyContext = createContext();

export const GroupAndStrategyProvider = ({ children }) => {
    const [strategyGroups, setStrategyGroups] = useState([]);
    const [allStrategies, setAllStrategies] = useState([]);
    const [strategyDetails, setStrategyDetails] = useState(null);
    const [activeTrade, setActiveTrade] = useState(null);  // Add this line
    const navigate = useNavigate();
    const fetchWithToken = useFetchWithToken();

    // Add this function
    const handleUpdateClick = (trade, navigateFlag = true) => {
        setActiveTrade(trade);
        if (navigateFlag) {
            navigate('/update-trade');
        }
    };

    const handleEditClick = (trade) => {
        setActiveTrade(trade);
        navigate('/edit-trade')
    };

//**loads all strategy groups */
    const loadStrategyGroups = async () => {
        try {
            const response = await fetchWithToken('api/strategygroups/');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setStrategyGroups(data);
        } catch (error) {
            console.error('Error fetching strategy groups:', error);
        }
    };

// Fetch all strategies (both grouped and ungrouped)
const loadAllStrategies = async () => {
    try {
        const response = await fetchWithToken('api/strategies/');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setAllStrategies(data);
    } catch (error) {
        console.error('Error fetching strategies:', error);
    }
};

//**ok so this part is not included in the fetch, it is imported into StrategyPage.js
//, it doesnt have to be included in the fetct, not at this stage at least
    const fetchStrategyDetails = async (strategySlug) => {
        try {
            const response = await fetchWithToken(`api/strategies/${strategySlug}/`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setStrategyDetails(data);
        } catch (error) {
            console.error('Error fetching strategy details:', error);
        }
    };
    // const removeGroupFromState = groupId => {
    //     setStrategyGroups(prevGroups => prevGroups.filter(group => group.id !== groupId));
    // };

////////////////////////////////////////////////////////////////

    const fetchData = async () => {
        await loadStrategyGroups();
        await loadAllStrategies();
    };

    useEffect(() => {
        fetchData();
    }, []);



//**there is a lot of things in the value here, i have no idea 
//if this is too much for a context or if some of these thigns hsould be in a context at all
//also need to understand what is the jargon to talk about all this stuff to someone who knows 
    return (
        <GroupAndStrategyContext.Provider value={{ 
            strategyGroups,
            setStrategyGroups, 
            allStrategies,  
            strategyDetails, 
            fetchData,
            fetchStrategyDetails,
            loadStrategyGroups,
            loadAllStrategies,
            activeTrade,
            setActiveTrade,  // Expose activeTrade in the context
            handleUpdateClick  // Expose handleUpdateClick function in the context  
        }}>
            {children}
        </GroupAndStrategyContext.Provider>
    );
};
