import React from 'react';
import SearchBar from './content/SearchBar';
import CalendarContent from './CalendarContent';
import ActiveTable from './content/ActiveTable'
import LineChart from './content/LineChartDemo'
import BarChart from './content/BarChartDemo'
import BarChartWithTable from './content/BarChartCustom'
import ProfitBox from './content/profitbox'
import WinRateRR from './content/WinRateRR';
import { Settings } from 'lucide-react';

const DashboardContent = () => {
  return (
    <div className="text-white">

      <div className='flex items-center mb-4 md:justify-between'>
        <h1 className='text-greyishtext text-3xl font-semibold mr-2'>Dashboard</h1>
        <Settings size={28} color="currentColor" className="text-greyishtext hover:text-white"/>
      </div>

      <div className='flex flex-col md:flex-row mb-4 md:mb-8 md:justify-between'>
        <div className='w-full mb-4 md:w-1/2 md:mr-4 md:mb-8 md:mb-0'>
          <ProfitBox type='total' className=""/>
        </div>
        <div className='w-full md:w-1/2 md:ml-4'>
          <WinRateRR type='total' className=''/>
        </div>
      </div>



      {/* <BarChartWithTable /> */}
      <div className='mb-4 md:mb-8'><ActiveTable /></div>
      
      <div className='mb-4 md:mb-8'><CalendarContent /></div>
      
    </div>
  );
};

export default DashboardContent;
