// PlanTypeContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFetchWithToken } from '../../Api';

export const PlanTypeContext = createContext();


export const usePlanType = () => useContext(PlanTypeContext);

export const PlanTypeProvider = ({ children }) => {
    const [planType, setPlanType] = useState(false);
    const fetchWithToken = useFetchWithToken();

    useEffect(() => {
        const getPlanType = async () => {
            try {
                const response = await fetchWithToken('api/getplantype/');
                if (!response.ok) {
                    throw new Error('resposne not ok, couldnt get plan type');
                }
                const data = await response.json();
                console.log(data);
                setPlanType(data.plan_type)
            } catch(error){
                console.error('Error fetching plan type:', error);
            }
    
        };
        getPlanType();
    }, [])


    return (
        <PlanTypeContext.Provider value={{ planType, setPlanType }}>
            {children}
        </PlanTypeContext.Provider>
    );
};
