import React, { createContext, useState, useContext } from 'react';

// Create the context
const TradeContext = createContext();

// Provider component to encapsulate state and functionality
export const TradeProvider = ({ children }) => {
    const [trades, setTrades] = useState([]);

    // Fetch trades from the backend
    const fetchTrades = async () => {
        try {
            const response = await fetch('/api/trades');
            const data = await response.json();
            setTrades(data);
        } catch (error) {
            console.error('Failed to fetch trades:', error);
        }
    };

    // Update a specific trade
    const updateTrade = async (tradeId, tradeData) => {
        try {
            const response = await fetch(`/api/trades/${tradeId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(tradeData),
            });
            if (!response.ok) {
                throw new Error('Failed to update trade');
            }
            const updatedTrade = await response.json();
            setTrades(currentTrades => 
                currentTrades.map(trade => trade.id === tradeId ? updatedTrade : trade)
            );
        } catch (error) {
            console.error('Failed to update trade:', error);
        }
    };

    // Provide the context value
    return (
        <TradeContext.Provider value={{ trades, fetchTrades, updateTrade }}>
            {children}
        </TradeContext.Provider>
    );
};

// Custom hook to use the trade context
export const useTrades = () => useContext(TradeContext);
