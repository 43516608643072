// useLogout.js
import { useAuth } from './AuthContext'; 
import { useFetchWithToken } from '../../Api';

//dop i need to do anythign with xsrf token here
const useLogout = () => {
    const { logout } = useAuth();
    const fetchWithToken = useFetchWithToken();

    const handleLogout = async () => {    
        try {
            const response = await fetchWithToken('auth/logout/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',//includes the cookies in request
            });

            if (response.ok) {
                logout(); //use logout function in authconext file
            } else {
                console.error("Logout failed:", await response.json());
            }
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    return handleLogout;
};

export default useLogout;
