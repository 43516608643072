// import React from 'react';

// const DeleteStrategyButton = ({ strategyId, onDelete }) => {
//   const handleDelete = async () => {
//     if (window.confirm('Are you sure you want to delete this strategy?')) {
//       onDelete(strategyId);
//     }
//   };

//   return (
//     <button onClick={handleDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
//       Delete Strategy
//     </button>
//   );
// };

// export default DeleteStrategyButton;


import React, { useContext }from 'react';
import { useFetchWithToken } from '../../../Api';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';

const DeleteStrategyButton = ({ strategyId, onSuccess }) => {
    const { fetchData } = useContext(GroupAndStrategyContext);
    const fetchWithToken = useFetchWithToken();

    const handleDelete = async () => {
        try {
            const response = await fetchWithToken(`api/strategies/${strategyId}/`, {
                method: 'DELETE'
            });
            if (response.ok) {
                fetchData();
                if (onSuccess) {
                    onSuccess();  // Optional: any additional logic after delete
                }
                // Call onSuccess to handle any post-delete logic, such as redirecting
            } else {
                alert('Failed to delete the strategy.');
            }
        } catch (error) {
            console.error('Error deleting the strategy:', error);
            alert('Error deleting the strategy.');
        }
    };

    return (
        <button onClick={handleDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Delete Group
        </button>
    );
};
export default DeleteStrategyButton;
