import React, { useState, useContext, useEffect } from 'react';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';
import { useFetchWithToken } from '../../../Api';

const EnterTrade = ({ groupId, strategyId, onCancel }) => {
  const { allStrategies, strategyGroups, activeTrade, setActiveTrade } = useContext(GroupAndStrategyContext);
  const [selectedGroup, setSelectedGroup] = useState(activeTrade ? activeTrade.strategy_group : groupId || null);
  const [selectedStrategy, setSelectedStrategy] = useState(activeTrade ? activeTrade.strategy : strategyId || null);
  const [status, setStatus] = useState(activeTrade ? activeTrade.status : '');  // Use status field directly
  const [fetchedstrategies, setFetchedStrategies] = useState(allStrategies); // Initialize with all strategies
  const [openingTime, setOpeningTime] = useState(activeTrade ? activeTrade.trade_date : new Date().toISOString().slice(0, 16));
  const [closingTime, setClosingTime] = useState(activeTrade ? activeTrade.completion_date : new Date(new Date().getTime() + 3600000).toISOString().slice(0, 16));
  const [tradeType, setTradeType] = useState(activeTrade?.trade_type || 'profitLoss');
  const [tradeDetails, setTradeDetails] = useState({
    openPrice: activeTrade ? activeTrade.open_price : '',
    closePrice: activeTrade ? activeTrade.close_price : '',
    profitLoss: activeTrade ? activeTrade.profit_loss : ''
  });
  const [profitLossType, setProfitLossType] = useState(activeTrade ? activeTrade.profitLossType : '');
  const [activeStatusError, setActiveStatusError] = useState('');
  const [openClosePriceError, setOpenClosePriceError] = useState('');
  const fetchWithToken = useFetchWithToken();
  
  useEffect(() => {
    console.log('Initial trade type:', tradeType);
  }, []); 
  useEffect(() => {
    console.log('changing:', tradeType);
  }, []); 


  useEffect(() => {
    console.log('Active Trade Data on Mount:', activeTrade);
    console.log('Active Trade ID on Mount:', activeTrade?.id);
}, []);

  useEffect(() => {
    const fetchGroupStrategies = async () => {
      if (selectedGroup && selectedGroup !== 'all') {
        const response = await fetchWithToken(`api/strategies/?group=${selectedGroup}`);
        if (response.ok) {
          const data = await response.json();
          setFetchedStrategies(data);
        } else {
          console.error('Failed to fetch strategies:', response.status);
        }
      } else if (selectedGroup === 'all') {
        setFetchedStrategies(allStrategies);
      }
    };
    fetchGroupStrategies();
  }, [selectedGroup, allStrategies]);


  useEffect(() => {
    console.log("Trade Type Updated:", tradeType);
  }, [tradeType]);  // This ensures the useEffect runs every time tradeType changes.


  
useEffect(() => {
  if (selectedStrategy) {
    const strategy = allStrategies.find(s => s.id.toString() === selectedStrategy);
    if (strategy && strategy.group) {
      setSelectedGroup(strategy.group.toString());
    }
  }
}, [selectedStrategy, allStrategies]);



  const handleInputChange = (field, value) => {
    if (field === 'selectedStrategy') {
      setSelectedStrategy(value);
      const strategy = allStrategies.find(s => s.id.toString() === value);
      if (strategy && strategy.group) {
        setSelectedGroup(strategy.group.toString());
      } else {
        setSelectedGroup(null);  // Reset to no group if no group associated
      }
    } else {
      const newTradeDetails = { ...tradeDetails, [field]: value };
  
      if (tradeType === 'openClose' && (field === 'openPrice' || field === 'closePrice')) {
        if (newTradeDetails.openPrice && newTradeDetails.closePrice) {
          const profitOrLoss = parseFloat(newTradeDetails.closePrice) - parseFloat(newTradeDetails.openPrice);
          newTradeDetails.profitLoss = profitOrLoss.toFixed(2);
        }
      }
      setTradeDetails(newTradeDetails);
    }
  };


  useEffect(() => {
    if (!activeTrade) {
        const now = new Date();
        const dateTimeString = now.toISOString().slice(0, 16);
        setOpeningTime(dateTimeString);
        now.setHours(now.getHours() + 1); // Default closing time is one hour later
        setClosingTime(now.toISOString().slice(0, 16));
    }
}, []);

  const handleStatusChange = newStatus => {
    setStatus(newStatus);  // Just update the status directly
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Additional logging to check the state just before calculating isUpdate
    console.log('Active Trade at Submit:', activeTrade);
    console.log('Active Trade ID at Submit:', activeTrade?.id);

    const isUpdate = !!activeTrade?.id; // Check if this is an update operation
    const method = isUpdate ? 'PATCH' : 'POST'; // Use PATCH for updates, POST for new entries
    const url = isUpdate ? `api/trade-executions/${activeTrade.id}/update-trade/` : 'api/trade-executions/';

    console.log('Submit Clicked - isUpdate:', isUpdate);
        console.log('Submit Method:', method);
        console.log('Submit URL:', url);


    // Reset error messages initially (if desired, or you can remove this line to maintain persistent error messages until corrected)
    setActiveStatusError('');
    setOpenClosePriceError('');
    // Flags to indicate if any errors were found
    let anyErrors = false;
    // Check if the trade is active and both prices are entered
    if (status === 'Active' && tradeDetails.closePrice) {
      setActiveStatusError('Active trades cannot have a closing price.');
      anyErrors = true; // Stop form submission if there is an error
    }
    // Check if "Open & Close" trade type is missing an opening price
    if (tradeType === 'openClose' && !tradeDetails.openPrice) {
      setOpenClosePriceError('Open & Close trades must have an opening price.');
      anyErrors = true; // Stop form submission if there is an error
    }
    if (anyErrors) {
      return;
    }
    if (selectedStrategy === '') {
      alert('Please select a strategy.');
      return;
    }
    // if (!isActive && !isCompleted) {
    //   alert('Please select a trade status.');
    //   return;
    // }
    // Ensure a trade status is selected
    if (!status) {
      alert('Please select a trade status.');
      return;
    }
    if (new Date(closingTime) <= new Date(openingTime)) {
      alert('Closing time must be after the opening time.');
      return;
    }
    let outcome = 'Pending';  // Default for active trades
    let profitLossAmount = null;

    if (status === 'Completed') {
      if (tradeType === 'profitLoss') {
        // Directly determine outcome from the profitLoss amount entered by the user
        profitLossAmount = parseFloat(tradeDetails.profitLoss);
        outcome = profitLossType === 'profit' ? 'Win' : 'Loss';
      } else if (tradeType === 'openClose') {
        // Calculate based on open and close prices
        const profitOrLoss = parseFloat(tradeDetails.closePrice) - parseFloat(tradeDetails.openPrice);
        profitLossAmount = profitOrLoss;
        outcome = profitOrLoss > 0 ? 'Win' : 'Loss';
      }
    }
    const payload = {
      strategy_group: selectedGroup,
      strategy: selectedStrategy,
      // status: isActive ? 'Active' : 'Completed',
      status: status,
      trade_date: openingTime,
      completion_date: status === 'Completed' ? closingTime : undefined,
      trade_type: tradeType,
      open_price: tradeType === 'openClose' ? tradeDetails.openPrice : undefined,
      close_price: tradeType === 'openClose' && status === 'Completed' ? tradeDetails.closePrice : undefined,
      profit_loss_amount: profitLossAmount,
      outcome,
    };
    console.log('Submitting:', payload);
    console.log(isUpdate)
    console.log(method)
    try {
      const response = await fetchWithToken(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to submit the trade');
      }
  
      // Handle success
      console.log('Trade submitted successfully:', responseData);
      // Optionally, redirect or clear form, etc.
      setActiveTrade(null); // Reset activeTrade after successfully handling the form submission
    } catch (error) {
      console.error('Error submitting trade:', error.message);
      alert('Error submitting trade: ' + error.message);
    }  
  };
  


  return (
    <div className='dark-secondary-bg rounded-lg p-4 border-b border-customGray mt-12'>
      <div className='px-4'>
      <h2 className="text-white text-2xl mb-4">Enter Trade</h2>
      <form onSubmit={handleSubmit} onChange={() => {
          setActiveStatusError('');
          setOpenClosePriceError('');
        }}>

        <div className='flex'>
          {/* Date & Time input */}
          {/* ***this should autopopulate with the current days date and tiem but also shoudl be editable */}
          <div className="mb-4 w-1/2 pr-2">
            <label htmlFor="trade-date-time" className="block text-sm text-gray-500 mb-2">Opening Time:</label>
            <input type="datetime-local" id="trade-date-time" className="dark-primary-bg border border-customGray text-gray-200 p-2 rounded w-full" 
              value={openingTime} onChange={(e) => setOpeningTime(e.target.value)} />
          </div>
          {/* Closing Time input */}
          <div className="mb-4 w-1/2 pl-2">
            <label htmlFor="closing-time" className="block text-sm text-gray-500 mb-2">Closing Time:</label>
            <input type="datetime-local" id="closing-time" className="dark-primary-bg border border-customGray text-gray-200 p-2 rounded w-full"
              value={closingTime} onChange={(e) => setClosingTime(e.target.value)} />
          </div>
        </div>

        <div className='flex'>
            {/* Group selection dropdown */}
          <div className="mb-4 w-1/2 pr-2">
            <label htmlFor="group-select" className="block text-sm text-gray-500 mb-2">Select Group:</label>
            <select id="group-select" value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)} className="dark-primary-bg border border-customGray text-gray-200 p-2 rounded w-full">
              <option value={null}>-- No Group Selected --</option>
              {strategyGroups.map(group => (
                <option key={group.id} value={group.id}>{group.name}</option>
              ))}
            </select>
          </div>
          {/* Strategy selection dropdown */}
          <div className="mb-4 w-1/2 pl-2">
            <label htmlFor="strategy-select" className="block text-sm text-gray-500 mb-2">Select Strategy:</label>
            <select id="strategy-select" value={selectedStrategy} onChange={(e) => setSelectedStrategy(e.target.value)} className="dark-primary-bg border border-customGray text-gray-200 p-2 rounded w-full">
              <option value={null}>-- No Strategy Selected --</option>
              {fetchedstrategies.map(strategy => (
                <option key={strategy.id} value={strategy.id}>{strategy.name}</option>
              ))}
            </select>
          </div>
        </div>
        

        {/* Trade Status checkbox */}
        {/* *** i need another tick box that is for completed , i dont wan teither of them to be autofilled */}
        {/* *** when one is ticked it may effect required fields */}
        <div className="mb-4 flex">
          <div className='w-1/2'>
            <label htmlFor="trade-type" className="block text-sm text-gray-500 mb-2">Status:</label>
              <div className='flex'>
                <label className="flex items-center mr-4">
                  <input 
                    type="checkbox" 
                    // checked={isActive}
                    checked={status === 'Active'}  
                    onChange={() => handleStatusChange('Active')} 
                    className="form-checkbox h-5 w-5 text-green-600"             
                  />
                  <span className="ml-2 text-gray-200">Active</span>
                  </label>
                  <label className="flex items-center">
                  <input 
                    type="checkbox" 
                    // checked={isCompleted} 
                    checked={status === 'Completed'}
                    onChange={() => handleStatusChange('Completed')} 
                    className="form-checkbox h-5 w-5 text-green-600"             
                  />
                  <span className="ml-2 text-gray-200">Completed</span>
                  </label>
                  {activeStatusError && <div className="text-red-500 text-sm mt-2">{activeStatusError}</div>}
              </div>
          
        </div>

          {/* Trade type dropdown */}
      <div className='w-1/2 pl-2'>
      {/* <label htmlFor="strategy-select" className="block text-white mb-2">Select Strategy:</label> */}
        <label htmlFor="trade-type" className="block text-sm text-gray-500 mb-2">Trade Type:</label>
        <select id="trade-type" value={tradeType} onChange={e => setTradeType(e.target.value)} className='dark-primary-bg border border-customGray text-gray-200 p-2 rounded w-full'>
          {/* <option value="">Select Type</option> */}
          <option value="openClose">Open & Close</option>
          <option value="profitLoss">Profit/Loss</option>
        </select>
      </div>
        </div>

        
          



          {/* Conditional inputs and trade type dropdown */}
    <div className='flex flex-wrap  mt-6 mb-4 items-end'>
      {/* Conditional inputs based on trade type */}
      {tradeType === 'openClose' && (
        <div className='flex w-full justify-between'>
          <div className='w-1/2 pr-2'>
            <input type="number" placeholder="Opening Price" value={tradeDetails.openPrice} onChange={e => handleInputChange('openPrice', e.target.value)} className='dark-primary-bg border border-customGray text-white p-2 rounded w-full'/>
          </div>
          <div className='w-1/2 pl-2'>
            <input type="number" placeholder="Closing Price" value={tradeDetails.closePrice} onChange={e => handleInputChange('closePrice', e.target.value)} className='dark-primary-bg border border-customGray text-white p-2 rounded w-full'/>
          </div>
          
        </div>
      )}
      

      {tradeType === 'profitLoss' && ( 
        <div className='w-full flex justify-between'>
          <div className='w-1/2 pr-2'>
            {/* <label className="block text-white mb-2">Profit/Loss:</label> */}
            <input type="number" placeholder="Profit/Loss" value={tradeDetails.profitLoss} onChange={e => handleInputChange('profitLoss', e.target.value)} className='dark-primary-bg border border-customGray text-white placeholder-gray-500 p-2 rounded w-full'/>
          </div>
          <div className='w-1/2 flex pl-2'>
            <button type="button" onClick={() => setProfitLossType('profit')} className={`bg-green-500 text-white font-bold py-2 px-4 rounded w-full mr-1 ${profitLossType === 'profit' ? 'opacity-100' : 'opacity-40'}`}>
              Profit
            </button>
            <button type="button" onClick={() => setProfitLossType('loss')} className={`bg-red-500 text-white font-bold py-2 px-4 rounded w-full ml-1 ${profitLossType === 'loss' ? 'opacity-100' : 'opacity-40'}`}>
              Loss
            </button>
          </div>
        </div>
      )}

      
    </div>

    {openClosePriceError && <div className="text-red-500 text-sm mt-2">{openClosePriceError}</div>}
        
    {tradeType === 'openClose' && tradeDetails.openPrice && tradeDetails.closePrice && (   
    <div className="flex justify-center mb-6">
      <p>
        <span className={`text-2xl font-semibold ${tradeDetails.profitLoss >= 0 ? 'text-green-500' : 'text-red-600'}`}>
          {tradeDetails.profitLoss >= 0 ? `+${tradeDetails.profitLoss}` : tradeDetails.profitLoss}
        </span>
      </p>
    </div>
    )}
        {/* /Submit Button */}
        <div className="flex justify-center mt-8">
          <button 
            type="submit" 
            className="bg-blue-500 hover:bg-blue-700 
            text-white font-bold py-2 px-4 rounded-full text-lg w-36 mx-2">
            Enter Trade
          </button>
          <button 
            type="submit" 
            onClick={onCancel}
            className="bg-red-500 hover:bg-red-700 
            text-white font-bold py-2 px-4 rounded-full text-lg w-36 mx-2">
            
            Cancel
          </button>
        </div>
      </form>

      </div>
    </div>
  );
};

export default EnterTrade;
