//SidebarContext.js
//This context file is purely for passing expanded state to sidebar.js and content.js, 
// whether it is true or not so we can conditonally render the css stuff betwene sidebar and content
import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext(); // Create context

export const useSidebar = () => useContext(SidebarContext); // Hook for consuming context

export const SidebarProvider = ({ children }) => {
    const [expanded, setExpanded] = useState(true); // State inside the provider
  
    return (
        <SidebarContext.Provider value={{ expanded, setExpanded }}>
            {children}
        </SidebarContext.Provider>
    );
};
