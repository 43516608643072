import React from 'react';
// import Register from '.components/auth/Register';
// import FeaturesSection from './FeaturesSection';
// import Spacer from './spacertest';
import darkscreen from '../assets/darkscreen.png'
import realscreen from '../assets/realscreen.jpg'
import evening from '../assets/evening.jpg'
import tianjin from '../assets/tianjin.jpg'
import bigben from '../assets/bigben.jpg'
import PriceCard from './PriceCard';

const Home = () => {
  return (
    <div className='w-full mx-auto text-center'>

      <div className="flex flex-col md:flex-row w-full items-center pt-4 md:py-20 md:min-h-[70vh]">
        {/* Left Content */}
        <div className="w-full md:w-1/2 px-4 md:px-8 text-white col-span-6 md:pl-24 md:text-left">
          <h1 className="text-4xl md:text-4xl lg:text-8xl font-bold mb-4">Trading Journal</h1>
          <h1 className="text-xl font-bold mb-4">Simple Trade Logging & Analysis</h1>
          <button className="bg-twpurple hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded">Get Started</button>
        </div>
        {/* Right Content */}
        <div className="w-full mt-4 md:w-1/2 h-full md:pr-24">
            <img
              src={realscreen}
              alt="Placeholder Image"
              className="h-full w-auto border border-customGray rounded-lg"
            />
        </div>
      </div>


      {/* <section class="">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Keep It Simple</h2>
                <p class="mb-2 font-light text-gray-500 sm:text-xl dark:text-gray-400">Track your profit and loss across different Stratgeies and Groups</p>
                <p class="mb-2 font-light text-gray-500 sm:text-xl dark:text-gray-400">Analyse your trades using a variety of visual tools</p>
                <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Identify whats working and what isn't</p>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                <div className="flex justify-end">
                    <div className="w-full px-8 md:px-0 md:w-1/2">
                        <PriceCard plan="free" />
                    </div>
                </div>
                <div className="flex justify-start">
                    <div className="w-full px-8 md:px-0 md:w-1/2">
                        <PriceCard plan="pro" />
                    </div>
                </div>
            </div>
            <div>
                <button
                    href="/register"
                    className="mt-8 text-white bg-twpurple hover:bg-indigo-600 md:w-1/3 h-16
                    focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-3xl 
                    px-5 py-2.5 text-center dark:text-white dark:focus:ring-primary-900"
                >
                    Get Started
                </button>
            </div>
        </div>
      </section> */}







<footer class="mt-16">

    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
              <a href="" class="flex items-center">
                  <span class="mx-auto self-center text-2xl font-semibold whitespace-nowrap text-twpurple">TradeBooks</span>
              </a>
          </div>
          
              <div className='md:text-left'>
                  <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                  <ul class="text-gray-500 dark:text-gray-400 font-medium text-center md:text-left">
                      <li class="mb-4">
                          <a href="#" class="hover:underline">Privacy Policy</a>
                      </li>
                      <li>
                          <a href="#" class="hover:underline">Terms &amp; Conditions</a>
                      </li>
                  </ul>
              </div>
         
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
          <div>
            <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="" class="hover:underline">TradeBooks™</a>. All Rights Reserved.
            </span>
          </div>  
      </div>
    </div>
</footer>

    </div>
  );
}

export default Home;