// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/auth/AuthContext';
// import { SidebarActiveProvider } from './components/dashboard/SidebarActiveContext';
import Dashboard from './components/dashboard/Dashboard';
import PublicFrontPage from './components/PublicFrontPage.js';
import RefreshWarningModal from './RefreshWarningModal';

import { TradeProvider } from './components/dashboard/TradeContext.js'





const AppWrapper = () => {
  // console.log("AppWrapper rendered");
  return (
    <Router>
      <AuthProvider>
        {/* <SidebarActiveProvider> */}
          <TradeProvider>
 
              <App />

          </TradeProvider>
        {/* </SidebarActiveProvider> */}
      </AuthProvider>
    </Router>
  );
};

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className='max-w-screen overflow-hidden'>
      <Routes>
        <Route path="/*" element={isAuthenticated ? <Dashboard /> : <PublicFrontPage />} />
        {/* <Route path="/*" element={<div className='dark-primary-bg'>{isAuthenticated ? <Dashboard /> : <PublicFrontPage />}</div>} /> */}
      </Routes>
    </div>
  );
};

export default AppWrapper;
