// StrategyPage.js
import React, { useContext, useState, useEffect } from 'react';
import { useFetchWithToken } from '../../../Api';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';
import DeleteStrategyButton from './DeleteStrategyButton';
import { useNavigate} from 'react-router-dom';

const StrategyPage = ({ id , slug }) => {
    const navigate = useNavigate();
    const [strategyDetails, setStrategyDetails] = useState(null);
    const [loading, setLoading] = useState(true); // State to track loading status
    const [error, setError] = useState(null); // State to track errors
    const fetchWithToken = useFetchWithToken();

    // Log the received strategyId
    console.log("Received strategyId:", id);
    console.log('Rendering StrategyPage...');
    console.log("Received strategySlug:", slug);

    
    useEffect(() => {
        // console.log("GroupId received:", groupId); // Log to check the received groupId
        const fetchStrategyDetails = async () => {
            try {
                const response = await fetchWithToken(`api/strategies/${id}/`);
                const data = await response.json();
                setStrategyDetails(data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch strategy details:', err);
                setError(err);
                setLoading(false);
            }
            
        };
        fetchStrategyDetails();
        
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading strategy details.</div>;
    }

    // Adding fetchStrategyDetails as a dependency

    if (!strategyDetails) {
        return <div>Loading...</div>;
    }

    const handleDeleteSuccess = () => {
        navigate('/strategies'); // Redirect after deletion
    };

    return (
        <div className='bg-lightBlue'>
            <h1>{strategyDetails.name}</h1>
            <p>{strategyDetails.description}</p>
            {/* More details rendering */}



            <div>
                {strategyDetails && (
                    <DeleteStrategyButton strategyId={id} onSuccess={handleDeleteSuccess} />
                )}
            </div>
        </div>
    );
};

export default StrategyPage;


