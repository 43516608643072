import React from 'react';
import { useNavigate } from 'react-router-dom'; 
// import { useSidebarActive } from '../SidebarActiveContext';

const StrategyGroupCard = ({ slug, id, groupName, description, strategyCount, performanceScore, otherInfo }) => {
  const navigate = useNavigate();
  // const { setSidebarActiveContent } = useSidebarActive();

  // const handleGroupClick = () => {
  //   setSidebarActiveContent({ type: 'strategyGroupDetails', groupId: id });
  // };
  const handleGroupClick = () => {
    navigate(`/strategy-groups/${id}-${slug}`);
};

  return (
    <div className="dark-secondary-bg hover:bg-indigo-900 p-4 border border-indigo-900 hover:border-purpletest rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 ease-in-out text-white h-40"
      onClick={handleGroupClick}
    >
      {/* Top half (70%) */}
      <div className="pb-2" style={{ height: '70%' }}>
        <h3 className="text-xl font-semibold mb-1">{groupName}</h3>
        <p className="text-gray-300 text-sm">{description}</p>
      </div>

      {/* Bottom half (30%) split into three boxes */}
      <div className="flex justify-between items-center pt-2 border-t border-gray-700" style={{ height: '30%' }}>
        {/* Box 1: Strategy Count */}
        <div className="text-center flex-1">
          <span className="font-bold text-lg">{strategyCount}</span>
          <p className="text-gray-400 text-xs mt-1">Strategies</p>
        </div>

        {/* Box 2: Performance Score */}
        <div className="text-center flex-1">
          <span className="font-bold text-lg">{performanceScore}%</span>
          <p className="text-gray-400 text-xs mt-1">Performance</p>
        </div>

        {/* Box 3: Other Info */}
        <div className="text-center flex-1">
          <span className="font-bold text-lg">{otherInfo}</span>
          <p className="text-gray-400 text-xs mt-1">Info</p>
        </div>
      </div>
    </div>
  );
};

export default StrategyGroupCard;
