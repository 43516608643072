// Sidebar.js
import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { ChevronFirst, ChevronLast } from "lucide-react"; // Adjust icons as needed
import { useSidebar } from './SidebarContext';
import { Settings, LogOut } from "lucide-react";
// import { useAuth } from '../auth/AuthContext'; // Import the useAuth hook
// import { useSidebarActive } from './SidebarActiveContext';
import {Home, LayoutDashboard, Calendar, LineChart, Plus, Star, Menu, X} from "lucide-react"
import useLogout from '../auth/logout';
import EnterTradeButton from "./content/EnterTradeButton";
import UpgradeButton from "./content/UpgradeButton";
import { usePlanType } from "./PlanTypeContext";


export const SidebarItem = ({ icon, text, onClick, to, isActive, setActive, mobileSidebarVisible, toggleMobileSidebar, className }) => {
    const { expanded } = useSidebar(); // Use the context
    const navigate = useNavigate();
    
    const handleClick = () => {
        if (onClick) {
            onClick(); // Call the onClick prop if it exists
        } else if (to) {
            navigate(to); // Navigate if 'to' prop exists
        }
        if(setActive){
            setActive(text);
        }

        // Check if mobile sidebar is visible and toggle it
        if (mobileSidebarVisible) {
            toggleMobileSidebar();
        }
        
    };
  //bg-purpletest hover:bg-indigo-900
    return (
        <li 
            // className={`font-medium text-greyishtext sidebar-item flex items-center rounded-lg p-3 hover:bg-indigo-900 hover:text-white transition-all duration-300 ${expanded ? 'justify-start mx-2' : 'justify-center mx-auto h-12 w-12'} ${isActive ? 'bg-indigo-700 text-white' : ''}`} 
            className={`${className} font-medium mx-2 mb-2 rounded border border-indigo-900 text-greyishtext sidebar-item flex items-center py-2 hover:bg-indigo-500 hover:text-white transition-all duration-300 ${expanded ? 'justify-start px-4' : 'rounded justify-center mx-auto h-12 w-12'} ${isActive ? 'bg-indigo-500 text-white' : ''}`} 

            onClick={handleClick}
        >
            {icon && <span className={expanded ? "mr-3" : ""}>{icon}</span>}
            <span className={`${expanded ? 'block' : 'hidden'}`}>{text}</span>
        </li>
    );
};


const Sidebar = () => {
    const { expanded, setExpanded } = useSidebar(); // Use context to manage the sidebar state
    const navigate = useNavigate();
    // const { setSidebarActiveContent } = useSidebarActive();
    const handleLogout = useLogout();
    const [activeItem, setActiveItem] = useState(null);
    const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false);
    const {planType} = usePlanType();

    const toggleMobileSidebar = () => {
        setMobileSidebarVisible(!mobileSidebarVisible);
    };

    const handleEnterTradeClick = () => {
        navigate('/enter-trade', {
          state: { groupId: '', strategyId: '' }
        });
      };

    return (
        <>  
            <div className={`fixed absolute top-2 right-4 z-50 md:hidden`}>
            {!mobileSidebarVisible && (
                <button
                    onClick={toggleMobileSidebar}
                    className="py-2 text-greyishtext"
                >
                    <Menu size={24} />
                </button>
            ) }
            </div>
            
            <div className={`SIDEBAR dark-primary-bg transition-all duration-300 ${expanded ? "w-64" : "w-20"} h-screen pb-2 text-white fixed flex flex-col justify-between border-r border-indigo-900 hidden md:flex`}>
                <div>
                {/* style={{ color: '#57c3ff'}} */}
                    <div className="flex items-center justify-between p-4">
                        <span className={`text-[1.7rem] text-twpurple font-bold transition-opacity duration-300 ${expanded ? 'md:block' : 'md:hidden'} block`}>
                            {/* <span style={{ color: '#6c72ff' }}>Trade</span> */}
                            {/* <span style={{ color: '#6c72ff' }}>Trade</span> */}
                            TradeBooks
                            {/* <span style={{ color: '#57c3ff' }}>Trade</span>
                            <span style={{ color: '#57c3ff' }}>books</span> */}
                        </span>
                        {/* <img src={logo} alt="Logo" className={`w-24 h-auto transition-all duration-300 ${expanded ? "block" : "hidden"}`} /> */}
                        <button 
                            onClick={() => setExpanded(!expanded)} 
                            className={`p-2 rounded-md text-greyishtext hover:text-white ${expanded ? "" : "mx-auto"} hidden md:block`}>
                            {expanded ? <ChevronFirst size={28} color='currentColor' className="text-indigo-500 hover:text-indigo-400" /> : <ChevronLast size={28} color='currentColor' className="text-indigo-500"/>}
                        </button>      
                    </div>
                    <div>
                        <div className="flex flex-col justify-between">
                            <div>
                                <SidebarItem 
                                    icon={<LayoutDashboard size={20} />} 
                                    text="Dashboard" 
                                    to="/dashboard"
                                    isActive={activeItem === "Dashboard"}
                                    setActive={setActiveItem}
                                    />
                                <SidebarItem 
                                    icon={<LineChart size={20} />} 
                                    text="Strategies" 
                                    to="/strategies" 
                                    isActive={activeItem === "Strategies"}
                                    setActive={setActiveItem}
                                    />
                                {/* <SidebarItem icon={<Calendar size={20} />} text="Calendar" to="/calendar" /> */}
                                {/* <SidebarItem icon={<Star size={20} />} text="Favourites" /> */}
                            </div> 
                            {/* <button 
                                onClick={handleEnterTradeClick}
                                className={`mt-2 ${expanded ? 'bg-green-600 hover:bg-green-500 mx-3' : 'mx-auto bg-green-600 hover:bg-green-500 mx-auto'} text-white font-semibold rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 flex items-center justify-center ${expanded ? 'py-2 px-4' : 'w-12 h-12'}`}
                                // className="m-3 bg-lime-500 hover:bg-lime-600 text-white 
                                // font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform 
                                // transition-colors duration-200"
                            >
                                {/* {expanded ? <span>Enter Trade</span> : <Plus size={20} />} */}
                                {/* {expanded ? "Enter Trade" : <Plus size={28} />} */}

                            {/* </button> */} 
                            <EnterTradeButton 
                                strategyId={null} 
                                groupId={null} 
                                // variant="strategyTable" 
                                className={` ${expanded ? 'bg-coolorsgreen3 hover:bg-green-500 mx-2' : 'mx-auto bg-green-600 hover:bg-green-500 mx-auto'} text-white font-semibold rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 flex items-center justify-center ${expanded ? 'py-2 px-4' : 'w-12 h-12'}`}
                            >
                                {expanded ? "Enter Trade" : <Plus size={28} />}
                            </EnterTradeButton>
                        </div> 
                    </div>
                </div>
                <div className="flex flex-col justify-between">
                    {/* <hr className="my-3" /> */}
                    {/* <UpgradeButton className={`mt-2 ${expanded ? 'bg-indigo-600 hover:bg-indigo-500 mx-2' : 'mx-auto bg-indigo-600 hover:bg-indigo-500 mx-auto'} text-white font-semibold rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 flex items-center justify-center ${expanded ? 'py-2 px-4' : 'w-12 h-12'}`}>
                        {expanded ? "Upgrade To Pro" : 'Pro' }
                    </UpgradeButton> */}

                    {!planType && (
                        <UpgradeButton className={`mb-2 border border-indigo-900 ${expanded ? 'hover:bg-indigo-500 mx-2' : 'mx-auto hover:bg-indigo-500 mx-auto'} text-white font-semibold rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 flex items-center justify-center ${expanded ? 'py-2 px-4' : 'w-12 h-12'}`}>
                            {expanded ? "Upgrade To Pro" : 'Pro' }
                        </UpgradeButton>
                    )}
                    
                    <SidebarItem 
                        icon={<Settings size={20} />} 
                        text="Settings"
                        isActive={activeItem === "Settings"}
                        setActive={setActiveItem}
                        to='/settings' 
                    />
                    <SidebarItem 
                        icon={<LogOut size={20} />} 
                        text="Logout" 
                        onClick={handleLogout} 
                    />
                </div>    
            </div>

            {/* Mobile Sidebar */}
            {mobileSidebarVisible && (
                <div className="fixed inset-0 dark-primary-bg z-40 flex flex-col items-center text-white w-screen">
                    <div className="flex justify-between items-center w-full p-4">
                        <span className="text-2xl text-twpurple font-bold">TradeBooks</span>
                        <button
                            onClick={toggleMobileSidebar}
                            className="p-2 text-greyishtext"
                        >
                            <X size={24} />
                        </button>
                    </div>
                    <nav className="flex flex-col w-3/4 items-center space-y-4 mt-4">
                        <SidebarItem 
                            icon={<LayoutDashboard size={20} />} 
                            text="Dashboard" 
                            to="/dashboard"
                            isActive={activeItem === "Dashboard"}
                            setActive={setActiveItem} 
                            mobileSidebarVisible={mobileSidebarVisible}
                            toggleMobileSidebar={toggleMobileSidebar}
                            className="w-full mb-0"
                            />
                        <SidebarItem 
                            icon={<LineChart size={20} />} 
                            text="Strategies" 
                            to="/strategies" 
                            isActive={activeItem === "Strategies"}
                            setActive={setActiveItem}
                            mobileSidebarVisible={mobileSidebarVisible}
                            toggleMobileSidebar={toggleMobileSidebar}
                            className="w-full"
                            />
                        <SidebarItem 
                            icon={<Settings size={20} />} 
                            text="Settings"
                            isActive={activeItem === "Settings"}
                            setActive={setActiveItem}
                            to='/settings' 
                            mobileSidebarVisible={mobileSidebarVisible}
                            toggleMobileSidebar={toggleMobileSidebar}
                            className="w-full"
                            />
                        <SidebarItem icon={<LogOut size={20} />} text="Logout" onClick={handleLogout} className="w-full"/>
                    </nav>
                </div>
            )}
        </>                    
    );
};
export default Sidebar;








