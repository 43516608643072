import React, { useState } from 'react';
import { Outlet, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSidebar } from './SidebarContext';
import SearchBar from './content/SearchBar'; // Assuming you have a SearchBar component
// import { useSidebarActive } from './SidebarActiveContext';
import StrategiesPage from './StrategiesPage';
import CreateGroupForm from './content/CreateGroupForm';
import CreateStrategyForm from './content/CreateStrategyForm';
import StrategyGroupPage from './content/StrategyGroupPage';
import StrategyPage from './content/StrategyPage';
import SettingsPage from './SettingsPage.js';

import DashboardContent from './DashboardContent'; // Update import paths as needed
// import StrategiesContent from './StrategiesContent'; // Update import paths as needed
import CalendarContent from './CalendarContent';
import EnterTrade from './content/EnterTrade';

import PrivateRoute from '../routing/PrivateRoute.js';




const Content = () => {
    const { expanded } = useSidebar(); // Access sidebar state
    // const { sidebarActiveContent } = useSidebarActive();
    // console.log('sidebarActiveContent.type:', sidebarActiveContent.type);
    const navigate = useNavigate();
    const [selectedContent, setSelectedContent] = useState('dashboard'); // Initialize with default content
    const location = useLocation(); 
    const pathSegments = location.pathname.split('/').filter(Boolean);


    const [groupPageId, setGroupPageId] = useState('');

    console.log(groupPageId)

    // Function to render content based on selectedContent state
    const renderContent = () => {
        if (pathSegments[0] === 'strategy-groups' && pathSegments.length > 1) {
            const [groupId, ...slugParts] = pathSegments[1].split('-');
            const groupSlug = slugParts.join('-'); // Join back in case slug contains dashes
    
            return <StrategyGroupPage groupId={groupId} groupSlug={groupSlug} setGroupPageId={setGroupPageId} />;
        } else if (pathSegments[0] === 'strategies' && pathSegments.length > 1) {
            const [strategyId, ...slugParts] = pathSegments[1].split('-');
            const strategySlug = slugParts.join('-');

            return <StrategyPage id={strategyId} slug={strategySlug} />;
        }
        switch (location.pathname) {
            case '/dashboard':
                return <DashboardContent />;
            case '/strategies':
                return <StrategiesPage />;
            case '/calendar':
                return <CalendarContent />;
            case '/enter-trade':
                return <EnterTrade groupPageId={groupPageId} />;
            case '/update-trade':
                return <EnterTrade />;
            case '/create-group':
                return <CreateGroupForm />;
            case '/create-strategy':
                return <CreateStrategyForm />;
            case '/settings':
                return <SettingsPage />;
            // Add other paths and corresponding components as needed
            default:
                return <DashboardContent />;  // Fallback or default content
        }
    };

    return (
        <>
            <div className={`CONTENT min-h-screen pt-12 px-12 dark-primary-bg flex flex-1 transition-margin duration-300 ${expanded ? 'ml-64' : 'ml-20'} h-full hidden md:flex`}>    
                <div className="w-full max-w-6xl mx-auto">
                {renderContent()}
                </div>
                   
            </div>
            <div className='CONTENTmobile w-full min-h-screen max-w-screen md:hidden mx-4 my-2'>
                {renderContent()}  
            </div>
        </>
        
    )
}

export default Content;