import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useFetchWithToken } from '../../Api';

const CalendarContent = () => {
    const [monthlyData, setMonthlyData] = useState({});
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const fetchWithToken = useFetchWithToken();
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [modalContent, setModalContent] = useState(''); // State for modal content

    useEffect(() => {
        fetchMonthlyData(currentYear, currentMonth + 1);
    }, [currentMonth, currentYear]);

    const fetchMonthlyData = async (year, month) => {
        try {
            const response = await fetchWithToken(`/api/trade-executions/monthly_profit_loss/?year=${year}&month=${month}`);
            if (response.ok) {
                const data = await response.json();
				console.log('Fetched data:', data);
                const processedData = data.reduce((acc, item) => {
                    acc[item.trade_date__date] = {
                        daily_profit_loss: item.total_profit - item.total_loss,
                        trade_count: item.trade_count,
                        win_loss: item.win_loss
                    };
                    return acc;
                }, {});
				console.log("Processed data:", processedData);
                setMonthlyData(processedData);
            } else {
                console.error('Error fetching monthly data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching monthly data:', error);
        }
    };

    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getFirstDayOfMonth = (month, year) => {
        return new Date(year, month, 1).getDay();
    };

    const daysInMonth = getDaysInMonth(currentMonth, currentYear);
    const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
        days.push(i);
    }

    const handleNextMonth = () => {
        setCurrentMonth((prevMonth) => {
            let newMonth = prevMonth + 1;
            let newYear = currentYear;

            if (newMonth > 11) { 
                newMonth = 0;
                newYear = currentYear + 1;
            }

            setCurrentYear(newYear);
            return newMonth;
        });
    };

    const handlePrevMonth = () => {
        setCurrentMonth((prevMonth) => {
            let newMonth = prevMonth - 1;
            let newYear = currentYear;

            if (newMonth < 0) { 
                newMonth = 11;
                newYear = currentYear - 1;
            }

            setCurrentYear(newYear);
            return newMonth;
        });
    };

    const prevMonthOverflowDays = Array.from(
        { length: firstDayOfMonth }, 
        (_, i) => getDaysInMonth(currentMonth - 1, currentYear) - firstDayOfMonth + i + 1
    );

    const daysInCurrentMonth = getDaysInMonth(currentMonth, currentYear);
    const totalDaysShown = firstDayOfMonth + daysInCurrentMonth;
    const nextMonthOverflowDays = Array.from(
        { length: (7 - (totalDaysShown % 7)) % 7 }, 
        (_, i) => i + 1
    );

    const totalDays = getDaysInMonth(currentMonth, currentYear);
    const totalCells = prevMonthOverflowDays.length + daysInMonth + nextMonthOverflowDays.length;
    const numberOfRows = Math.ceil(totalCells / 7);


    const openModal = (day) => {
        const dateKey = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const data = monthlyData[dateKey];
        setModalContent(data ? JSON.stringify(data) : 'No data for this day');
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='relative'>
            <div className="lg:flex lg:h-full lg:flex-col dark-secondary-bg mb-8 rounded-lg border border-indigo-900">
                <header className="flex items-center justify-between border-b border-gray-700 px-6 py-4 lg:flex-none">
                    <h1 className="text-base font-semibold leading-6 text-white">
                        <time dateTime={`${currentYear}-${currentMonth + 1}`}>
                            {new Date(currentYear, currentMonth).toLocaleDateString('default', { month: 'long', year: 'numeric' })}
                        </time>
                    </h1>
                    <div className="flex items-center">
                        <div className="relative flex items-center rounded-md shadow-sm md:items-stretch bg-purpletest">
                            <button
                                type="button"
                                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-stone-700 pr-1 text-gray-300 hover:text-gray-200 focus:relative md:w-9 md:pr-0 md:hover:bg-purpletest bg-purpletest"
                                onClick={handlePrevMonth}
                            >
                                <span className="sr-only">Previous month</span>
                                <ChevronLeft size={16} />
                            </button>
                            <button
                                type="button"
                                className="hidden border-y border-stone-700 px-3.5 text-sm font-semibold text-gray-300 bg-purpletest hover:bg-purpletest focus:relative md:block"
                            >
                                Month
                            </button>
                            <span className="relative -mx-px h-5 w-px bg-stone-700 md:hidden"></span>
                            <button
                                type="button"
                                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-stone-700 pl-1 text-gray-300 hover:text-gray-200 focus:relative md:w-9 md:pl-0 md:hover:bg-stone-700"
                                onClick={handleNextMonth}
                            >
                                <span className="sr-only">Next month</span>
                                <ChevronRight size={16} />
                            </button>
                        </div>
                        <div className="hidden md:ml-4 md:flex md:items-center">
                            <div className="relative">
                            </div>
                            <button
                                type="button"
                                className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Add Trade
                            </button>
                        </div>
                    </div>
                </header>
                <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                    <div className="grid grid-cols-7 gap-px text-center text-xs font-semibold leading-6 text-gray-400 lg:flex-none">
                        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(day => (
                            <div key={day}>{day}</div>
                        ))}
                    </div>
                    <div className="flex text-xs leading-6 text-gray-400 lg:flex-auto">
                        <div className={`hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-${numberOfRows} lg:gap-px`}>
                            {prevMonthOverflowDays.map(day => (
                                <div key={`prev-${day}`} className="w-full rounded-md relative h-24 flex items-center justify-center text-gray-500 border border-customGray">
                                    {/* <span className="absolute top-1 right-2 text-xs">{day}</span> */}
                                </div>
                            ))}
                            
                            {days.map(day => (
                                <button
                                    key={day}
                                    onClick={() => openModal(day)}
                                    className={`relative rounded-md w-full h-24 border border-customGray flex items-center ${
                                        monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.win_loss === 'win' ? "bg-calgreenbg hover:bg-green-700 hover:border-bordercalgreen" :
                                        monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.win_loss === 'loss' ? "bg-calredbg hover:bg-red-900 hover:border-bordercalred" :
                                        monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.win_loss === 'even' ? "bg-gray-500 bg-opacity-50 hover:bg-gray-400 hover:bg-opacity-50 hover:border-gray-500" : "hover:bg-indigo-900"
                                    }`}
                                >
                                    <span className="absolute text-gray-300 top-1 right-2 text-xs">{day}</span>
                                    {/* <span className="text-lg ml-4">
                                        {monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.daily_profit_loss ? 
                                        `$${monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.daily_profit_loss}` : ''}
                                    </span> */}
                                    <div className='flex flex-col ml-4 text-white'>
                                        <span className="text-lg">
                                            {monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.win_loss === 'even' ? 
                                            "EVEN" :
                                            monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.daily_profit_loss ? 
                                            `$${Math.abs(monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.daily_profit_loss)}` : ''}
                                        </span>
                                        <span className="text-xs">
                                            {monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.trade_count ? 
                                            `${monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.trade_count} Trade${monthlyData[`${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`]?.trade_count > 1 ? 's' : ''}` : ''}
                                        </span>
                                    </div>
                                    
                                </button>
                            ))}

                            {nextMonthOverflowDays.map(day => (
                                <div key={`next-${day}`} className="w-full rounded-md relative h-24 flex items-center justify-center text-gray-500 border border-customGray">
                                    {/* <span className="absolute top-1 right-2 text-xs">{day}</span> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="absolute top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="dark-secondary-bg p-8 h-48 rounded shadow-lg relative">
                        <button onClick={closeModal} className="absolute top-0 right-0 m-4 font-bold text-indigo-600">X</button>
                        <div>{modalContent}</div>
                    </div>
                </div>
            )}
        </div>
        
    );
};

export default CalendarContent;


