// CreateStrategyForm.js
import React, { useState, useContext } from 'react';
import { useFetchWithToken } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';

const CreateStrategyForm = ({ preSelectedGroup, onCancel, groupId, onStrategyCreated }) => {
    const [strategyName, setStrategyName] = useState('');
    const [description, setDescription] = useState('');
    // const [selectedGroup, setSelectedGroup] = useState(preSelectedGroup);
    const [selectedGroup, setSelectedGroup] = useState(groupId || '');
    const navigate = useNavigate();
    const { strategyGroups, fetchData } = useContext(GroupAndStrategyContext);
    const [parameters, setParameters] = useState([]);
    const fetchWithToken = useFetchWithToken();

    const handleAddParameter = () => {
        setParameters([...parameters, { description: '' }]);
    };
    const handleRemoveParameter = index => {
        setParameters(prevParameters => prevParameters.filter((_, idx) => idx !== index));
    };

    const handleParameterChange = (index, event) => {
        const newParameters = parameters.map((parameter, paramIndex) => {
            if (index === paramIndex) {
                return { ...parameter, description: event.target.value };
            }
            return parameter;
        });
        setParameters(newParameters);
    };

    // Ensure the "Ungrouped" group ID is set if no group is selected
        const ungroupedGroupId = strategyGroups.find(group => group.name === 'Ungrouped')?.id || null;
        const groupToSubmit = selectedGroup || ungroupedGroupId;

    const handleSubmit = async (event) => {
        event.preventDefault();
        const strategyData = {
            name: strategyName,
            description,
            // group: selectedGroup ? selectedGroup.id : null,
            group: groupToSubmit,
            parameters: parameters.map(param => ({ description: param.description }))
        };

        console.log('Selected Group:', selectedGroup);

        try {
            const response = await fetchWithToken('api/strategies/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(strategyData),
            });

            if (response.ok) {
                const newStrategy = await response.json();
                setStrategyName('');
                setDescription('');
                // Optionally reset selectedGroup if the form is reused
                setSelectedGroup(preSelectedGroup);
                setParameters([{ description: '' }]); // Reset parameters

                fetchData(); // Refresh the context data
                // navigate(`/strategies/${newStrategy.id}-${newStrategy.slug}`);
                onStrategyCreated();

                //Zebra Highlight here
            } else {
              // Consolidated error handling for non-OK responses
              const errorResponse = await response.json(); // Get detailed error message if available
              console.error('Failed to create strategy, server response:', errorResponse);
              throw new Error(errorResponse.message || 'Failed to create strategy');
          }
        } catch (error) {
            console.error('Error creating strategy:', error);
        }
    };

    return (
        <div>
        <form className="border-b border-customGray dark-secondary-bg p-4 rounded-lg mb-4" onSubmit={handleSubmit}>
            <div className="mb-4">
                <label htmlFor="strategy-name" className="block text-white mb-2">Strategy Name:</label>
                <input
                    type="text"
                    id="strategy-name"
                    value={strategyName}
                    onChange={(e) => setStrategyName(e.target.value)}
                    className="bg-gray-700 text-white p-2 rounded w-full"
                    placeholder="Enter strategy name"
                    required
                />
            </div>
            <div className="mb-4">
                <label htmlFor="description" className="block text-white mb-2">Description:</label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="bg-gray-700 text-white p-2 rounded w-full"
                    placeholder="Enter strategy description"
                />
            </div>
            { !preSelectedGroup && (
                  <div className="mb-4">
                      <label htmlFor="group" className="block text-white mb-2">Group:</label>
                      <select
                          id="group" 
                          className="bg-gray-700 text-white p-2 rounded w-full"                
                          value={selectedGroup}
                          onChange={(e) => setSelectedGroup(e.target.value)} 
                      >
                          {/* The first option represents the choice to not select any group */}
                          <option value="">No Group Selected</option>
                          {/* Map over groupOptions, which is an array of group objects passed as a prop, 
                              creating an option element for each group */}
                          {strategyGroups.map(group => (
                              // For each group, create an option element with a key and value
                              <option key={group.id} value={group.id}>
                                  {group.name} // The text displayed in the dropdown for this option is the group's name
                              </option>
                          ))}
                      </select>
                  </div>
              )}
            
              {parameters.map((parameter, index) => (
                <div key={index} className="mb-4">
                    <label htmlFor={`parameter-${index}`} className="block text-white mb-2">Parameter {index + 1}:</label>
                    <div className='flex items-center'>
                        <input
                            type="text"
                            id={`parameter-${index}`}
                            className="bg-gray-700 text-white p-2 rounded w-full flex-1"
                            value={parameter.description}
                            onChange={(event) => handleParameterChange(index, event)}
                            placeholder="Enter parameter description"
                        />
                        <button 
                            type="button" 
                            onClick={() => handleRemoveParameter(index)}
                            className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            X
                        </button>
                    </div>
                    

                </div>
            ))}
            <div className='mb-4 text-center'>
                <button type="button" 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" 
                    onClick={handleAddParameter}>
                    +
                </button>
            </div>
            
            <div className='text-center'>
                <button type="submit" className="w-40 mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Create Strategy
                </button>
                <button type="button" onClick={onCancel} className="w-40 ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                    Cancel
                </button>
            </div>
        </form>
    </div>
    );
};

export default CreateStrategyForm;
