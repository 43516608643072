// CreateGroupButton.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { useSidebarActive } from './SidebarActiveContext';


const CreateGroupButton = ({onClick}) => {
  const navigate = useNavigate();

  // const { setSidebarActiveContent } = useSidebarActive();

    // const handleClick = () => {
    //   navigate('/create-group');
    // };


    return (
      <div
        // className="flex items-center justify-center border-2 border-dashed border-gray-400 rounded-lg bg-gray-700 hover:bg-gray-600 cursor-pointer"
        className="flex p-2 items-center justify-center border hover:border-2 border-dashed border-indigo-900 hover:border-purpletest rounded-lg dark-secondary-bg hover:bg-indigo-900 cursor-pointer"

        onClick={onClick}
      >
        <div className="text-center">
          <svg
            className="w-12 h-12 text-gray-300 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <p className="mt-2 text-white text-lg">Create New Group</p>
        </div>
      </div>
    );
  };
  
  export default CreateGroupButton;
  