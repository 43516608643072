import React, { useState,useContext, useEffect } from 'react';
import { useFetchWithToken } from '../../../Api';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';
import { useNavigate } from 'react-router-dom';


const GroupSettingsModal = ({groupDetails, onUpdate, onDelete, onCancel}) => {
    const [name, setName] = useState(groupDetails.name);
    const [description, setDescription] = useState(groupDetails.description);
    const fetchWithToken = useFetchWithToken();
    const groupId = groupDetails.id;
    const { fetchData } = useContext(GroupAndStrategyContext);
    const navigate = useNavigate();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmWord, setConfirmWord] = useState('');
    const [confirmWordError, setConfirmWordError] = useState('');

    const handleUpdate = (e) => {
        e.preventDefault();
        onUpdate({ name, description });
    };

    const handleDelete = async () => {
        if(!confirmDelete){
            setConfirmDelete(true);
            return;
        } else if (confirmWord !== name){
            setConfirmWordError('they do not match');
            return;
        }


        try {
            const response = await fetchWithToken(`api/strategygroups/${groupId}/delete_group/`, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                // Include authentication headers if necessary
            },
            });
            if (response.ok) {
                //in cases of delete if we try to parse respsoen ro store response we will get an error
                //because djanrestframework might return an empty body as reposne in case of dleet requests
                await fetchData();
                navigate('/strategies')

            } else {
                const responseData = await response.json();
                throw new Error(responseData.message || 'Failed to delete group');
            }
        } catch (error) {
            console.error('Error deleting group:', error.message);
        }

    };

    console.log('modalgroupDetails:', groupDetails);

    // useEffect(() => {
    //     if (groupDetails) {
    //         setName(groupDetails.name);
    //         setDescription(groupDetails.description);
    //     } else {
    //         console.log('groupDetails is null or undefined');
    //     }
    // }, [groupDetails]);

    return (
        <div className="p-4 md:p-12 shadow-2xl w-full dark-secondary-bg border border-indigo-900 grid grid-cols-1 mb-8 rounded-lg">
            <div className='flex '>
                <h2 className="text-white text-2xl mb-4 mr-4">Group Settings:</h2>
                <h1 className='text-white text-2xl font-semibold'>{groupDetails.name}</h1>
            </div>
            
            <form >
                {!confirmDelete ? (
                    <>
                        <div className="mb-4 text-white">
                        <label className="block text-white mb-1" htmlFor="name">Name</label>
                        <input 
                            className="w-full p-2 dark-primary-bg border border-customGray rounded"
                            id="name"
                            type="text"
                            // placeholder={name}
                            defaultValue={groupDetails.name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        </div>
                        <div className="mb-4 text-white">
                            {/* <label className="block text-white mb-1" htmlFor="description">Description</label> */}
                            <textarea
                                className="w-full p-2 border border-customGray rounded dark-primary-bg"
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </>  
                ) : (
                    <>
                        <div className="mb-4 text-white">
                            {/* <label className="block text-white mb-1" htmlFor="description">{description}</label> */}
                            <p>Please type the name of the group exactly as it is</p>
                            {confirmWordError && <p className="text-red-500">{confirmWordError}</p>}
                            <textarea
                                className="w-full p-2 border border-customGray rounded dark-primary-bg"
                                id="description"
                                value={confirmWord}
                                onChange={(e) => setConfirmWord(e.target.value)}
                            />
                        </div>
                    </>
                 )}
                
                <div className="flex justify-between">
                    <button type="button" onClick={handleDelete} className="bg-red-600 text-white p-2 rounded">Delete Group</button>
                    <button type="submit" className="bg-blue-600 text-white p-2 rounded">Save Changes</button>
                </div>
            </form>
            <button onClick={onCancel}>Close</button>
        </div>
    );
};

export default GroupSettingsModal;
