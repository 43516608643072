// Header.js
import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header className="py-4 border-b border-indigo-900">
      <div className="mx-auto flex justify-between items-center px-4 md:px-24">
        <h1 className="text-2xl font-bold  text-twpurple"><a href="/">TradeBooks.io</a></h1>
        <nav className="hidden md:block mt-4">
          <ul className="flex space-x-4">
            <li><a href="/" className="hover:text-gray-300 text-gray-300 font-semibold">HOME</a></li>
            <li><a href="/register" className="hover:text-gray-300 text-gray-300 font-semibold">REGISTER</a></li>
            <li><a href="/login" className="font-semibold px-3 py-1 rounded hover:text-gray-300 text-gray-300 bg-twpurple hover:bg-indigo-600">LOG IN</a></li>
          </ul>
        </nav>
        <button className="md:hidden p-2" onClick={toggleMobileMenu}>
          {mobileMenuOpen ? <X size={24} /> : <Menu size={24} color="currentColor" className="text-white" />}
        </button>
      </div>

      {/* Mobile Menu */}
      <div className={`fixed inset-0 bg-gray-900 bg-opacity-90 z-50 flex flex-col items-center text-white ${mobileMenuOpen ? 'block' : 'hidden'} md:hidden w-screen`}>
        <div className="flex justify-between items-center w-full p-4">
          {/* <span className="text-2xl font-bold">TradeBooks.io</span> */}
          <button onClick={toggleMobileMenu} className="p-2 bg-gray-800 text-white rounded-full mx-auto ">
            <X size={24} />
          </button>
        </div>
        <nav className="flex flex-col items-center space-y-4 mt-4">
          <a href="/" className="hover:text-gray-300 text-gray-300 font-semibold">HOME</a>
          <a href="/register" className="hover:text-gray-300 text-gray-300 font-semibold">REGISTER</a>
          <a href="/login" className="font-semibold px-3 py-1 rounded hover:text-gray-300 text-gray-300 bg-twpurple hover:bg-indigo-600">LOG IN</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
