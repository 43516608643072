import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Plus} from "lucide-react"


const EnterTradeButton = ({ variant,inlineForm, onInlineFormClick, strategyId, groupId, className, expanded, children }) => {
    // console.log("Received groupId in EnterTradebutton:", groupId);
    const navigate = useNavigate();

    const handleClick = () => {
        if (inlineForm && onInlineFormClick) {
            onInlineFormClick();
        } else {
            navigate('/enter-trade', {
                state: { groupId, strategyId }    
            });
            console.log('Navigating with state:', { groupId, strategyId });
        }
    };

    

    // // Define button styles based on the variant
    // let buttonClasses = 'text-white font-bold px-2 rounded';
    // if (variant === 'groupPage') {
    //     buttonClasses += ' bg-green-500 hover:bg-green-700 py-2'; // Full width, green color, w-full taken out
    // } else if (variant === 'strategyItem') {
    //     buttonClasses += ' bg-red-500 hover:bg-red-700 py-1 w-auto'; // Auto width, red color
    // } else if (variant === 'strategyTable') {
    //     buttonClasses += ' bg-purpletest hover:bg-indigo-900 font-semibold py-1.5 w-auto text-sm m-2';
    // // } else if (variant === 'strategyETButton') {
    //     // buttonClasses += ` mt-4 ${expanded ? 'bg-green-600 hover:bg-green-500 mx-3' : 'mx-auto bg-green-600 hover:bg-green-500 mx-auto'} text-white font-semibold rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 flex items-center justify-center ${expanded ? 'py-2 px-4' : 'w-12 h-12'}`; // Auto width, red color
    // } else {
    //     buttonClasses += ' bg-blue-500 hover:bg-blue-700 py-2 w-auto'; // Default styling
    // }
    // // Combine the existing classes with the passed className prop
    // buttonClasses = `${buttonClasses} ${className}`;

    return (
        <button onClick={handleClick} className={className}>
            {children || "Enter Trade"}
        </button>
    );
};

export default EnterTradeButton;
