import React, { useState, useEffect } from 'react';
import { useFetchWithToken } from '../../../Api';
import { Doughnut } from 'react-chartjs-2';

const WinRateBox = ({ type, typeId, className }) => {
    const [winRateData, setWinRateData] = useState({ wins: 0, losses: 0 });
    const [winRatePercentage, setWinRatePercentage] = useState('');
    const [hasData, setHasData] = useState(false);
    const fetchWithToken = useFetchWithToken();

    useEffect(() => {
        const fetchWinRate = async () => {
            let endpoint = '';
            switch (type) {
                case 'total':
                    endpoint = 'api/trade-executions/win-loss-totals/';
                    break;
                case 'strategy':
                    endpoint = `api/trade-executions/${typeId}/win-loss-by-strategy/`;
                    break;
                case 'group':
                    endpoint = `api/trade-executions/${typeId}/win-loss-by-group/`;
                    break;
                default:
                    console.error('Unknown type for fetching data');
                    return;
            }
            try {
                const response = await fetchWithToken(endpoint);
                if (response.ok) {
                    const data = await response.json();
                    const { wins, losses } = data;
                    setWinRateData({ 
                        wins: wins.count, 
                        losses: losses.count, 
                        averageWinAmount: wins.average_amount,
                        averageLossAmount: losses.average_amount 
                    });
                    const total = wins.count + losses.count;
                    const winRateCalc = total ? ((wins.count / total) * 100).toFixed(2) + '%' : '0%';
                    setWinRatePercentage(winRateCalc);
                    setHasData(total > 0);
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching win rate:', error);
            }
        };
        fetchWinRate();
    }, [type, typeId]);

    const chartConfig = {
        data: {
            labels: ['Wins', 'Losses'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [winRateData.wins, winRateData.losses],
                    backgroundColor: [
                        // 'rgba(75, 192, 192, 0.8)',  // Green
                        // 'rgba(255, 99, 132, 0.8)',  // Red
                        'rgba(16, 185, 129, 1)',  // Tailwind green-500
                        'rgba(239, 68, 68, 1)',   // Tailwind red-500
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 0,
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    };

    return (
        <>
        {hasData ? (
            <div className={`${className} dark-secondary-bg border border-indigo-900 p-4 rounded-lg text-white md:min-h-32`}>
                <div className='flex flex-col items-center'>
                    <div className='pb-2 md:pb-0'>
                        <span className=''>Win Rate: {winRatePercentage || 'Calculating...'}</span>
                        <p>Avg Win: {winRateData.averageWinAmount}</p>
                        <p>Avg Loss: {winRateData.averageLossAmount}</p>
                    </div>
                    <div className="w-full">
                        <Doughnut {...chartConfig} />
                    </div>
                </div>
            </div>
        ) : (
            <div className={`${className} dark-secondary-bg border border-indigo-900 p-4 rounded-lg text-white md:min-h-32 flex items-center justify-center`}>
                <span>No Data</span>
            </div>
        )}
    </>
    );
};

export default WinRateBox;

//classes i had in here previsouly h-full profit-box dark-secondary-bg border border-indigo-900 p-4 rounded-lg flex flex-col items-center justify-center