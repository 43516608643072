import React, { useState,useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EnterTradeButton from './EnterTradeButton';
import { useFetchWithToken } from '../../../Api';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';
import EnterTrade from './EnterTrade';


const ActiveTable = ({ groupId }) => {
    const navigate = useNavigate();
    const [activeTrades, setActiveTrades] = useState([]);
    const { allStrategies, handleUpdateClick } = useContext(GroupAndStrategyContext);
    const fetchWithToken = useFetchWithToken();
    const [hasActive, setHasActive] = useState(false);


    const [activeTradeId, setActiveTradeId] = useState(null);

    const toggleFormVisibility = (tradeId) => {
        // Toggle based on whether the same trade ID is clicked again or another one
        setActiveTradeId(activeTradeId === tradeId ? null : tradeId);
    };

    const hideEnterTradeForm = () => {
        setActiveTradeId(null);
    };

    useEffect(() => {
        // console.log('fetchWithToken:', fetchWithToken);

        const fetchActiveTrades = async () => {
            console.log('fetchActiveTrades called');
            let url = '/api/trade-executions/?status=Active';
            if (groupId) {
                url += `&strategy_group=${groupId}`; // Corrected parameter name to strategy_group
            }
            const response = await fetchWithToken(url, {
                headers: {
                    'Content-Type': 'application/json',
                    // Add your auth token here if needed
                },
                
            });
            if (response.ok) {
                const data = await response.json();
                setActiveTrades(data);
                setHasActive(data.length > 0);       
            } else {
                // Handle errors
                console.error('Failed to fetch active trades');
            }
        };

        fetchActiveTrades();
    }, []);

    const getStrategyName = (strategyId) => {
        const strategy = allStrategies.find(strategy => strategy.id === strategyId);
        return strategy ? strategy.name : 'No Strategy';
    };


//border-gradient-blue-to-green
    return (
        <div className="dark-secondary-bg border border-indigo-900 rounded-lg grid grid-cols-1">
            <div className='h-16 flex items-center justify-between px-8 border-b border-customGray'>
                <div className='text-white text-xl font-semibold'>Active Trades</div>
                <div></div>
            </div>
            {hasActive ? (
                <>
                {activeTrades.map((trade, index) => (
                <React.Fragment key={trade.id}>
                    {/* <div key={trade.id} 
                        className={`card overflow-hidden ${index !== activeTrades.length - 1 ? 'border-b border-customGray' : ''} ${activeTradeId === trade.id ? 'bg-blue-500' : 'bg-transparent'}`}
                        className={`card overflow-hidden ${index !== activeTrades.length - 1 ? 'border-b border-customGray' : ''}`}
                    > */}
                    <div key={trade.id} 
                        className={`card hover:bg-indigo-950 overflow-hidden ${index !== activeTrades.length - 1 ? 'border-b border-customGray' : ''} ${activeTradeId === trade.id ? 'bg-darkprimbg' : 'bg-transparent'}`}
                    >
                        <div className="p-2 flex justify-between items-center px-8">
                            <div className="w-1/4">
                                <p className="text-white text-base">{getStrategyName(trade.strategy)}</p>
                            </div>
                            <div className="w-1/4 flex justify-start">
                                <p className="text-gray-600">{trade.trade_date}</p>
                            </div>
                            <div className="w-1/4 justify-center">
                                {/* <p className="text-gray-600">{trade.strategy_group ? trade.strategy_group.name : 'No Group'}</p> */}
                                <p className="text-gray-600">{trade.group_name || 'No Group'}</p>
                            </div>
                            <div className="w-1/4 flex justify-end">
                                <button onClick={() => {toggleFormVisibility(trade.id); handleUpdateClick(trade, false);}} className="bg-amber-500 hover:bg-amber-600 text-sm text-white font-semibold py-1.5 px-4 rounded">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                    {activeTradeId === trade.id && (
                        <EnterTrade tradeId={trade.id} onCancel={hideEnterTradeForm}/>
                    )}
                </React.Fragment>
            ))}
                </>
            ) : (
                <div className='text-gray-600 text-md my-2 text-center'>No Actve Trades</div>
            )}
            
        </div>
    );
};

export default ActiveTable;
