// StrategyGroupPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useFetchWithToken } from '../../../Api';  
import CreateStrategyButton from '../CreateStrategyButton';
import CreateStrategyForm from './CreateStrategyForm';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';//**do we need still need this */
import StrategyCard from './StrategyCard';
import EnterTradeButton from './EnterTradeButton';
import { useLocation, useNavigate} from 'react-router-dom';
import DeleteGroupButton from './DeleteGroupButton';
import StrategyTable from './StrategyTable';
import ActiveTable from './ActiveTable'
import CompletedTable from './CompletedTable';
import { Settings } from 'lucide-react';
import ProfitBox from './profitbox';
import WinRateRR from './WinRateRR';
import GroupSettingsModal from './GroupSettingsModal';


const StrategyGroupPage = ({ setGroupPageId, groupId}) => {
    const [groupDetails, setGroupDetails] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [groupStrategies, setGroupStrategies] = useState([]);
    const location = useLocation();
    const numericGroupId = +groupId;
    const navigate = useNavigate();
    const fetchWithToken = useFetchWithToken();
    const [showGroupSettings, setGroupSettings] = useState(false);

    console.log("Received groupId:", groupId);
    console.log("Type of groupId:", typeof groupId);

    useEffect(() => {
        // console.log("GroupId received:", groupId); // Log to check the received groupId
        const fetchGroupDetails = async () => {
            const response = await fetchWithToken(`api/strategygroups/${groupId}/`);
            const data = await response.json();            
            setGroupDetails(data);
            
            const strategyResponse = await fetchWithToken(`api/strategies/?group=${groupId}`);
            const strategyData = await strategyResponse.json();
            setGroupStrategies(strategyData);
        };
        fetchGroupDetails();
        //const groupPageId = groupId  //changed form belwo as this was redindant, didnt need to do all the split as groupiud is pased from content
        // const groupPageId = parseInt(location.pathname.split('/')[2].split('-')[0]); // Extracting the group page ID
        setGroupPageId(numericGroupId);
    }, [groupId, location.pathname, setGroupPageId]);//Depednancies 
        //these dependancies are what the useeffect looks for chanegs in to update things

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const refreshStrategies = async () => {
        try {
            const strategyResponse = await fetchWithToken(`api/strategies/?group=${groupId}`);
            const strategyData = await strategyResponse.json();
            setGroupStrategies(strategyData);
        } catch (error) {
            console.error('Error fetching strategies:', error);
        }
    };

    if (!groupDetails) {
        return <div>Loading...</div>;
    }

    const handleDeleteSuccess = () => {
        navigate('/strategies'); // Redirect after deletion
    };

    const toggleGroupSettingsModal = () => {
        setGroupSettings(!showGroupSettings)
    }

    const handleUpdateGroup = async (updatedDetails) => {
        try {
            const response = await fetch(`/api/groups/${groupId}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedDetails),
            });
            if (response.ok) {
                const updatedGroup = await response.json();
                // setGroupInfo(updatedGroup);
                toggleGroupSettingsModal();
            }
        } catch (error) {
            console.error('Failed to update group details', error);
        }
    };

    const handleDeleteGroup = async () => {
        try {
            const response = await fetch(`/api/groups/${groupId}/`, {
                method: 'DELETE',
            });
            if (response.ok) {
                // Handle successful deletion, e.g., redirect to another page or update state
                console.log('Group deleted successfully');
                toggleGroupSettingsModal();
            }
        } catch (error) {
            console.error('Failed to delete group', error);
        }
    };

    // console.log('groupDetails before rendering modal:', groupDetails);

    return (
        <div className=''>

            
            <div className='flex items-center mb-4 md:justify-between'>
                <h1 className='text-greyishtext text-3xl font-semibold mr-2'>{groupDetails.name}</h1>
                <Settings onClick={toggleGroupSettingsModal} size={28} color="currentColor" className="text-greyishtext hover:text-white"/>
                {/* {showGroupSettings && <GroupSettingsModal onCancel={toggleGroupSettingsModal} />}  
                <p className='text-customGray'>{groupDetails.description}</p> */}
            </div>
            {/* <div className='relative'> */}
            {showGroupSettings && (<GroupSettingsModal groupDetails={groupDetails} onCancel={toggleGroupSettingsModal} />)} 
            {/* </div> */}

            <div className='flex flex-col md:flex-row mb-4 md:mb-8 md:justify-between'>
                <div className='w-full mb-4 md:w-1/2 md:mb-0 md:mr-4'><ProfitBox type='group' typeId={groupId}/></div>
                <div className='w-full md:w-1/2 md:ml-4'><WinRateRR type='group' typeId={groupId}/></div>
            </div>
            
            {/* {showForm && <CreateStrategyForm groupId={numericGroupId} onCancel={toggleFormVisibility} />} */}

            <div className='mb-4 md:mb-8'>
                <ActiveTable groupId={groupId}/>
            </div>
            
            <div className='mb-4 md:mb-8'>
                <StrategyTable 
                    strategies={groupStrategies} 
                    groupId={groupId} 
                    title={"Strategies"}
                    refreshStrategies={refreshStrategies} 
                />
            </div>
            
            <div className='mb-4 md:mb-8'>
                <CompletedTable groupId={groupId} />
            </div>
            


            {/* Add a recent tardes box here similar ot thwe strategiues table */}
            {/* <div>
                {groupDetails && (
                    <DeleteGroupButton groupId={groupId} onSuccess={handleDeleteSuccess} />
                )}
            </div> */}
        </div>
        
    );
};

export default StrategyGroupPage;










{/* <div>
                {groupStrategies.map(strategy => (
                    <StrategyCard 
                        key={strategy.id} 
                        id={strategy.id} 
                        slug={strategy.slug} 
                        strategyName={strategy.name} 
                        description={strategy.description}
                    />
                ))}
            </div> */}