import React, { useState, useEffect } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { useFetchWithToken } from '../../../Api';  // Make sure the path is correct
import { Line } from 'react-chartjs-2';

const ProfitBox = ({ type, typeId, className }) => {
    const [profitLoss, setProfitLoss] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const fetchWithToken = useFetchWithToken();

    // const [chartData, setChartData] = useState(null);


    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const fetchProfitLossData = async () => {
        try {
            let endpoint = '';
            let profitLossKey = '';  // This will store the key to extract from the response
            switch (type) {
                case 'total':
                    endpoint = 'api/trade-executions/total_profit_loss/';
                    profitLossKey = 'totalProfitLoss';
                    break;
                case 'strategy':
                    endpoint = `api/trade-executions/${typeId}/strategy_profit_loss/`;
                    profitLossKey = 'strategyProfitLoss';
                    break;
                case 'group':
                    endpoint = `api/trade-executions/${typeId}/group_profit_loss/`;
                    profitLossKey = 'groupProfitLoss';
                    break;
                default:
                    console.error('Unknown type for profit loss data');
                    return; // Optionally handle this case more gracefully
            }
    
            const data = await fetchWithToken(endpoint);
            if (data.ok) {
                const result = await data.json();
                console.log(result);
                setProfitLoss(result[profitLossKey]);  // Use the key to extract the right value
                // prepareChartData(result[profitLossKey]);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching profit or loss data:', error);
        }
    };



    useEffect(() => {
        fetchProfitLossData();
    }, [type, typeId]);  // Refetch when type or typeId changes

    return (
        <div className={`${className} dark-secondary-bg border border-indigo-900 p-4 rounded-lg text-white`}>
            <div className='flex flex-col items-center'>
                <span className='font-semibold text-4xl px-2'>${profitLoss}</span>
                <h3 className="text-lg font-semibold">Balance</h3>
                {/* <p>+5% this month</p> */}
            </div>
            
        </div>
    );
};

export default ProfitBox;


//classes i ahd on here previsly h-full text-white profit-box dark-secondary-bg border border-indigo-900 p-4 rounded-lg flex flex-col items-center justify-center