import React, { useState,useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EnterTradeButton from './EnterTradeButton';
import { useFetchWithToken } from '../../../Api';
import { GroupAndStrategyContext } from '../GroupAndStrategyContext';
import { Wrench } from 'lucide-react';
import EnterTrade from './EnterTrade';



const CompletedTable = ({ groupId }) => {
    const navigate = useNavigate();
    const [completedTrades, setCompletedTrades] = useState([]);
    const { allStrategies, handleUpdateClick } = useContext(GroupAndStrategyContext);
    const fetchWithToken = useFetchWithToken();
    const [hasCompleted, setHasCompleted] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const tradesPerPage = 8; // Number of trades per page
    const [completedTradeId, setCompletedTradeId] = useState(null);

    useEffect(() => {
        const fetchCompletedTrades = async () => {
            let url = '/api/trade-executions/?status=Completed';
            if (groupId) {
                url += `&strategy_group=${groupId}`; // Corrected parameter name to strategy_group
            }
            const response = await fetchWithToken(url, {
                headers: {
                    'Content-Type': 'application/json',
                    // Add your auth token here if needed
                }
            });
            if (response.ok) {
                const data = await response.json();
                setCompletedTrades(data);
                setHasCompleted(data.length > 0); 
            } else {
                // Handle errors
                console.error('Failed to fetch completed trades');
            }
        };

        fetchCompletedTrades();
    }, []);

    const getStrategyName = (strategyId) => {
        const strategy = allStrategies.find(strategy => strategy.id === strategyId);
        return strategy ? strategy.name : 'No Strategy';
    };


    // Logic to get trades for the current page
    const indexOfLastTrade = currentPage * tradesPerPage;
    const indexOfFirstTrade = indexOfLastTrade - tradesPerPage;
    const currentTrades = completedTrades.slice(indexOfFirstTrade, indexOfLastTrade);

    // Logic to paginate
    const paginate = pageNumber => setCurrentPage(pageNumber);


    const toggleFormVisibility = (tradeId) => {
        // Toggle based on whether the same trade ID is clicked again or another one
        setCompletedTradeId(completedTradeId === tradeId ? null : tradeId);
    };
    const hideEnterTradeForm = () => {
        setCompletedTradeId(null);
    };

    return (
        <div className="dark-secondary-bg border border-customGray grid grid-cols-1 rounded-lg">
            <div className='h-16 flex items-center justify-between px-8 border-b border-customGray'>
                <div className='text-white text-xl font-semibold'>Completed Trades</div>
                <div></div>
            </div>
            {hasCompleted ? (
                <>
            {currentTrades.map((trade, index) => ( 
                <React.Fragment key={trade.id}>  
                <div key={trade.id} className={`card hover:bg-indigo-950 overflow-hidden ${index !== currentTrades.length - 1 ? 'border-b border-customGray' : ''} ${completedTradeId === trade.id ? 'bg-darkprimbg' : 'bg-transparent'}`}>
                    {/* Display trade details */}
                    <div className="p-2 grid grid-cols-5 items-center px-8">
                        <div className="col-span-1">
                            <p className="text-white text-base">{getStrategyName(trade.strategy)}</p>
                        </div>
                        <div className="col-span-1 flex justify-start">
                            <p className="text-gray-600">{trade.trade_date}</p>
                        </div>
                        <div className="col-span-1 flex justify-center">
                            <p className="text-gray-600">{trade.group_name}</p>
                        </div>
                        
                        {/* <div className="col-span-1 flex justify-end">
                            <button onClick={() => handleUpdateClick(trade)} className="bg-purple-800 hover:bg-purple-900 text-sm text-white font-semibold py-1.5 px-4 rounded">
                                Edit Trade
                            </button>
                        </div> */}
                        <div className="col-span-1 flex justify-center">
                            <button onClick={() => {toggleFormVisibility(trade.id); handleUpdateClick(trade, false);}} className="bg-purple-800 hover:bg-purple-900 text-sm text-white font-semibold py-1.5 px-4 rounded">
                                <Wrench size={16} color="white" />
                            </button>
                        </div>
                        <div className="col-span-1 flex justify-end">
                            <p className={`${trade.outcome === 'Win' ? 'text-green-600 border-green-600 bg-green-500 bg-opacity-20' : 'text-red-600 border-red-600 bg-red-500 bg-opacity-20'} w-20 border text-center`}>
                                {trade.outcome}
                            </p>
                        </div>
                    </div>
                </div>
                {completedTradeId === trade.id && (
                    <EnterTrade tradeId={trade.id} onCancel={hideEnterTradeForm}/>
                )}
                </React.Fragment> 
            ))}
            </>
            ) : (
                <div className='text-gray-600 text-md my-2 text-center'>No Completed Trades</div>
            )}





            {/* Pagination */}
            {currentTrades.length > 8 && (
                <div className="flex justify-center items-center py-2 border-t border-customGray">
                    <ul className="pagination flex space-x-2">
                        {Array.from({ length: Math.ceil(completedTrades.length / tradesPerPage) }, (_, index) => (
                            <li key={index} className="page-item">
                                <button onClick={() => paginate(index + 1)} className={`hover:text-gray-300 ${currentPage === index + 1 ? 'text-gray-300' : 'text-gray-600'}`}>
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CompletedTable;
